import { useState, useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
import { ArrowDownIcon } from "../../../icons";
import { userInfo } from "../../../config/recoil/atoms";
import { UserMenu } from "./UserMenu";

export type UserProps = {
    name?: string;
};
type IProps = {
    hide?: boolean;
};
export const User = ({ hide }: IProps) => {
    const info = useRecoilValue(userInfo);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [toggled, setToggled] = useState(false);

    const handleClickOutside = (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setToggled(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div
            ref={wrapperRef}
            className="flex sm:gap-4 items-center relative pr-4  cursor-pointer z-10"
            onClick={() => setToggled(!toggled)}
        >
            <img
                src={info.profile_image}
                alt="profile"
                className="object-cover rounded-full w-[32px] h-[32px] sm:w-[30px] sm:h-[30px]"
            />
            <div
                className={`flex gap-0 items-center gap-x-2 md:text-color7 ${toggled ? "text-color7" : "text-color8"
                    }`}
            >
                {info.name && (
                    <span className="whitespace-nowrap text-sm font-inter md:flex hidden">
                        Hi, {info.name}!
                    </span>
                )}
                <ArrowDownIcon
                    className={`${toggled ? "rotate-180" : ""
                        } text-base fill-current w-[10px] transform transition-all`}
                />
            </div>
            <UserMenu toggled={toggled} hide={hide} />
        </div>
    );
};
