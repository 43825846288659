import { useEffect, useState } from "react";
import { API, API_BASEURL, SWR_CONFIG, buildUrl } from "../../config";
import useGetSWR from "../../hooks/useGetSWR";
import { IRoleRes } from "../../types";
import { CustomSelect } from "./genericDropdowns/CustomSelect";

type InputProps = {
    title?: string;
    placeholder?: string;
    name?: string;
    onChangeFunction?: (arg0: any) => void;
    className?: string;
    inputClassName?: string;
    titleClassname?: string;
    editing?: boolean;
    userListRes?: any;
    textBoxClass?: string;
    value?: any;
    children?: JSX.Element | false | undefined;
    isMulti?: boolean;
    control?: React.CSSProperties;
    isClearable?: boolean;
};

export const DropDownRoles = ({
    title,
    placeholder = "",
    name = "",
    onChangeFunction,
    className,
    inputClassName,
    titleClassname,
    editing,
    userListRes,
    textBoxClass,
    value = "",
    children,
    isMulti = false,
    control,
    isClearable = true,
}: InputProps) => {
    const url = buildUrl(API.ALL_ROLES, {}, API_BASEURL);
    const { data: rolesRes } = useGetSWR<IRoleRes>(url, SWR_CONFIG.FORMS);

    const [roles, setRoles] = useState<any[]>([]);

    useEffect(() => {
        if (rolesRes?.roles) {
            setRoles(rolesRes.roles);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rolesRes]);

    const onChange = (val: any, action: any) => {
        if (!onChangeFunction) return "";
        if (action.action === "clear") onChangeFunction(isMulti ? [] : "");
        else onChangeFunction(isMulti ? val : val.value);
    };

    const toLabelValue = () => {
        const options = [];
        if (!roles) return [];
        for (const role of roles) {
            options.push({ value: isMulti ? role.name : role.id, label: role.name });
        }
        return options;
    };

    const getValue = () => {
        if (!roles) return "";
        const role = roles.find((r) => r.id === value);
        if (!role) return "";
        return { label: role.name, value: value };
    };

    const getValueMulti = () => {
        if (!roles) return "";
        const values = [];
        for (const v of value) {
            values.push({ label: v, value: v });
        }
        return values;
    };

    return (
        <div className="flex flex-col">
            <CustomSelect
                title={title}
                placeholder={placeholder}
                name={name}
                onChangeFunction={onChange}
                className={className}
                containerClassname="capitalize"
                titleClassName={titleClassname}
                editing={editing}
                selectStyles={selectStyles}
                value={isMulti ? getValueMulti() : getValue()}
                options={toLabelValue()}
                isObject
                multi={isMulti}
                control={control}
                isClearable={isClearable}
            >
                {children}
            </CustomSelect>
        </div>
    );
};

const selectStyles = (editing: boolean, children?: any) => {
    return {
        control: (state: any) => ({
            border: children ? "1px solid red" : "1px solid var(--color-color3)",
            color: !editing ? "var(--color-color1)" : "var(--color-color7)",
            display: "flex",
            outline: "2px solid transparent",
            outlineOffset: "2px",
            borderRadius: "7px",
            backgroundColor: !editing ? "var(--color-color15)" : "",
            fontSize: "14px",
            // height: "40px",
        }),
        dropdownIndicator: (provided: any) => ({
            ...provided,
            padding: "8px",
        }),
        indicatorSeparator: () => ({
            display: "none",
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            padding: "0 8px",
        }),
    };
};
