// Case sensitive. needs to be the same as auth0 dashboard
export const enum ROLES {
    SUPER_ADMIN = "Super Admin",
    OWNER = "Owner",
    ADMIN = "Admin",
    REAL_ESTATE_ADMIN = "Real Estate Admin",
    CREDIT_ADMIN = "Credit Admin",
    AGENT = "Agent",
    REAL_ESTATE_AGENT = "Real Estate Agent",
    FUNDING_AGENT = "Funding Agent",
    CREDIT_AGENT = "Credit Agent",
    SUB_ADMIN = "Subadmin",
    LEGAL = "Legal",
    ACCOUNTING = "Accounting",
    PROCESSING = "Processing",
    SUPERVISOR = "Supervisor",
    SETTLEMENT = "Settlement",
    DATA_ENTRY = "Data Entry",
    TELEMARKETING = "Telemarketing",
    SUPERIOR_AGENT = "Superior Agent",
}

// Case sensitive. needs to be the same as auth0 dashboard
export enum PERMISSIONS {
    VALIDATE_PROGRAM = "validate:program",
    VALIDATE_SPECIAL_PRICING_PROGRAM = "validate:special_pricing_program",
    READ_PROCESSING_STAGES_TABLE = "read:processing_stages",

    LEAD_ACCESS = "read:lead_tab",
    LEAD_READ = "read:lead",
    LEAD_READ_OWN = "read:lead_own",
    LEAD_CREATE = "create:lead",
    LEAD_UPDATE = "update:lead",
    LEAD_DELETE = "delete:lead",
    LEAD_IMPORT = "create:lead_import",
    LEAD_UPDATE_FLAG = "update:lead_flag",
    LEAD_UPDATE_UNFLAG = "update:lead_unflag",
    LEAD_UPDATE_AGENT = "update:lead_agent",
    LEAD_UPDATE_AGENT_OWN = "update:lead_agent_own",
    LEAD_UPDATE_RE_AGENT = "update:lead_re_agent",
    LEAD_UPDATE_RE_AGENT_OWN = "update:lead_re_agent_own",
    LEAD_UPDATE_FUNDING_AGENT = "update:lead_funding_agent",
    LEAD_UPDATE_FUNDING_AGENT_OWN = "update:lead_funding_agent_own",
    LEAD_UPDATE_LOAN_OFFICER = "update:lead_loan_officer",
    LEAD_UPDATE_LOAN_OFFICER_OWN = "update:lead_loan_officer_own",
    LEAD_DETAILS = "read:lead_details",
    LEAD_UPDATE_CONVERT = "update:lead_convert",
    LEAD_READ_OVERALL = "read:lead_overall",
    LEAD_UPDATE_OVERALL = "update:lead_overall",
    LEAD_CREATE_CREDIT_SCORES = "create:lead_credit_scores",
    LEAD_UPDATE_CREDIT_SCORES = "update:lead_credit_scores",
    LEAD_DELETE_CREDIT_SCORES = "delete:lead_credit_scores",
    LEAD_READ_PROGRAM = "read:lead_program",
    LEAD_CREATE_PROGRAM = "create:lead_program",
    // LEAD_UPDATE_PROGRAM_CONTRACT_CLOSED = "update:lead_program_contract_closed",
    // LEAD_UPDATE_PROGRAM_STATUS = "update:lead_program_status",
    // LEAD_READ_PROGRAM_AGENT_FEE = "read:lead_program_agent_fee",
    // LEAD_READ_PROGRAM_PRICE = "read:lead_program_price",
    // LEAD_READ_PROGRAM_INFO = "read:lead_program_info",
    // LEAD_DELETE_PROGRAM = "delete:lead_program",
    LEAD_READ_FINANCIAL = "read:lead_financial",
    LEAD_UPDATE_FINANCIAL = "update:lead_financial",
    LEAD_READ_DOCUMENT = "read:lead_document",
    LEAD_IMPORT_DOCUMENT = "import:lead_document",
    LEAD_DELETE_DOCUMENT = "delete:lead_document",
    LEAD_READ_NOTES = "read:lead_notes",
    LEAD_CREATE_NOTES = "create:lead_notes",
    LEAD_UPDATE_NOTES = "update:lead_notes",
    LEAD_DELETE_NOTES = "delete:lead_notes",
    LEAD_READ_CONVERSATION = "read:lead_conversation",
    LEAD_CREATE_CONVERSATION = "create:lead_conversation",

    CLIENT_ACCESS = "read:client_tab",
    CLIENT_READ = "read:client",
    CLIENT_READ_OWN = "read:client_own",
    CLIENT_READ_BANKRUPTCY = "read:client_bankruptcy",
    CLIENT_CREATE = "create:client",
    CLIENT_UPDATE = "update:client",
    CLIENT_DELETE = "delete:client",
    CLIENT_IMPORT = "create:client_import",
    CLIENT_UPDATE_AGENT = "update:client_agent",
    CLIENT_UPDATE_AGENT_OWN = "update:client_agent_own",
    CLIENT_UPDATE_RE_AGENT = "update:client_re_agent",
    CLIENT_UPDATE_RE_AGENT_OWN = "update:client_re_agent_own",
    CLIENT_UPDATE_FUNDING_AGENT = "update:client_funding_agent",
    CLIENT_UPDATE_FUNDING_AGENT_OWN = "update:client_funding_agent_own",
    CLIENT_UPDATE_LOAN_OFFICER = "update:client_loan_officer",
    CLIENT_UPDATE_LOAN_OFFICER_OWN = "update:client_loan_officer_own",
    CLIENT_UPDATE_FLAG = "update:client_flag",
    CLIENT_UPDATE_UNFLAG = "update:client_unflag",
    CLIENT_ACTIVITY = "read:client_activity",
    CLIENT_DETAILS = "read:client_details",
    CLIENT_PRODUCTION = "read:client_production",
    CLIENT_READ_OVERALL = "read:client_overall",
    CLIENT_UPDATE_OVERALL = "update:client_overall",
    CLIENT_CREATE_CREDIT_SCORES = "create:client_credit_scores",
    CLIENT_UPDATE_CREDIT_SCORES = "update:client_credit_scores",
    CLIENT_DELETE_CREDIT_SCORES = "delete:client_credit_scores",
    CLIENT_READ_PROGRAM = "read:client_program",
    CLIENT_CREATE_PROGRAM = "create:client_program",
    CLIENT_UPDATE_PROGRAM_CONTRACT_CLOSED = "update:client_program_contract_closed",
    CLIENT_UPDATE_PROGRAM_STATUS = "update:client_program_status",
    CLIENT_READ_PROGRAM_AGENT_FEE = "read:client_program_agent_fee",
    CLIENT_READ_PROGRAM_PRICE = "read:client_program_price",
    CLIENT_READ_PROGRAM_INFO = "read:client_program_info",
    CLIENT_DELETE_PROGRAM = "delete:client_program",
    CLIENT_READ_BANKRUPTCY_TAB = "read:client_bankruptcy_tab",
    CLIENT_UPDATE_BANKRUPTCY_TAB = "update:client_bankruptcy_tab",
    CLIENT_READ_PAYMENT = "read:client_payment",
    CLIENT_UNLOCK_PAYMENT_METHODS = "unlock:client_payment_methods",
    CLIENT_UPDATE_PAYMENT_METHODS = "update:client_payment_methods",
    CLIENT_READ_PAYMENT_TRANSACTIONS = "read:client_payment_transactions",
    CLIENT_CREATE_PAYMENT_TRANSACTIONS = "create:client_payment_transactions",
    CLIENT_UPDATE_PAYMENT_TRANSACTIONS = "update:client_payment_transactions",
    CLIENT_READ_PAYMENT_RE_TRANSACTIONS = "read:client_payment_re_transactions",
    CLIENT_CREATE_PAYMENT_RE_TRANSACTIONS = "create:client_payment_re_transactions",
    CLIENT_UPDATE_PAYMENT_RE_TRANSACTIONS = "update:client_payment_re_transactions",
    CLIENT_DELETE_PAYMENT_RE_TRANSACTIONS = "delete:client_payment_re_transactions",
    CLIENT_UPDATE_PAYMENT_INFO = "update:client_payment_info",
    CLIENT_READ_PAYMENT_FEES = "read:client_payment_fees",
    CLIENT_CREATE_PAYMENT_FEES = "create:client_payment_fees",
    CLIENT_UPDATE_PAYMENT_FEES = "update:client_payment_fees",
    CLIENT_DELETE_PAYMENT_FEES = "delete:client_payment_fees",
    CLIENT_READ_PAYMENT_FEES_TRANSACTIONS = "read:client_payment_fees_transactions",
    CLIENT_CREATE_PAYMENT_FEES_TRANSACTIONS = "create:client_payment_fees_transactions",
    CLIENT_UPDATE_PAYMENT_FEES_TRANSACTIONS = "update:client_payment_fees_transactions",
    CLIENT_READ_FINANCIAL = "read:client_financial",
    CLIENT_UPDATE_FINANCIAL = "update:client_financial",
    CLIENT_READ_PROCESSING = "read:client_processing",
    CLIENT_UPDATE_PROCESSING = "update:client_processing",
    CLIENT_READ_PROCESSING_PROCESS = "read:client_processing_process",
    CLIENT_CREATE_PROCESSING_PROCESS = "create:client_processing_process",
    CLIENT_DELETE_PROCESSING_PROCESS = "delete:client_processing_process",
    CLIENT_UPDATE_PROCESSING_PROCESS = "update:client_processing_process",
    CLIENT_READ_PROCESSING_PLATFORM_ACCESS = "read:client_processing_platform_access",
    CLIENT_CREATE_PROCESSING_PLATFORM_ACCESS = "create:client_processing_platform_access",
    CLIENT_DELETE_PROCESSING_PLATFORM_ACCESS = "delete:client_processing_platform_access",
    CLIENT_UPDATE_PROCESSING_PLATFORM_ACCESS = "update:client_processing_platform_access",
    CLIENT_READ_FUNDING = "read:client_funding",
    CLIENT_READ_FUNDING_CREDIT_LINES = "read:client_funding_credit_lines",
    CLIENT_CREATE_FUNDING_CREDIT_LINES = "create:client_funding_credit_lines",
    CLIENT_UPDATE_FUNDING_CREDIT_LINES = "update:client_funding_credit_lines",
    CLIENT_DELETE_FUNDING_CREDIT_LINES = "delete:client_funding_credit_lines",
    CLIENT_READ_FUNDING_MORTGAGE = "read:client_funding_mortgage",
    CLIENT_CREATE_FUNDING_MORTGAGE = "create:client_funding_mortgage",
    CLIENT_DELETE_FUNDING_MORTGAGE = "delete:client_funding_mortgage",
    CLIENT_UPDATE_FUNDING_MORTGAGE = "update:client_funding_mortgage",
    CLIENT_READ_FUNDING_BANK_ACCESS = "read:client_funding_bank_access",
    CLIENT_CREATE_FUNDING_BANK_ACCESS = "create:client_funding_bank_access",
    CLIENT_DELETE_FUNDING_BANK_ACCESS = "delete:client_funding_bank_access",
    CLIENT_UPDATE_FUNDING_BANK_ACCESS = "update:client_funding_bank_access",
    CLIENT_READ_TRADELINES = "read:client_tradelines",
    CLIENT_UPDATE_TRADELINES = "update:client_tradelines",
    CLIENT_READ_TRADELINES_TRADELINE = "read:client_tradelines_tradeline",
    CLIENT_CREATE_TRADELINES_TRADELINE = "create:client_tradelines_tradeline",
    CLIENT_DELETE_TRADELINES_TRADELINE = "delete:client_tradelines_tradeline",
    CLIENT_UPDATE_TRADELINES_TRADELINE = "update:client_tradelines_tradeline",
    CLIENT_READ_SETTLEMENTS = "read:client_settlements",
    CLIENT_READ_SETTLEMENTS_SETTLEMENT = "read:client_settlements_settlement",
    CLIENT_CREATE_SETTLEMENTS_SETTLEMENT = "create:client_settlements_settlement",
    CLIENT_DELETE_SETTLEMENTS_SETTLEMENT = "delete:client_settlements_settlement",
    CLIENT_UPDATE_SETTLEMENTS_SETTLEMENT = "update:client_settlements_settlement",
    CLIENT_CREATE_SETTLEMENTS_NOTIFICATION = "create:client_settlements_notification",
    CLIENT_READ_SETTLEMENTS_CREDIT_CARD = "read:client_settlements_credit_card",
    CLIENT_CREATE_SETTLEMENTS_CREDIT_CARD = "create:client_settlements_credit_card",
    CLIENT_DELETE_SETTLEMENTS_CREDIT_CARD = "delete:client_settlements_credit_card",
    CLIENT_UPDATE_SETTLEMENTS_CREDIT_CARD = "update:client_settlements_credit_card",
    CLIENT_READ_ACCOUNTS = "read:client_accounts",
    CLIENT_CREATE_ACCOUNTS = "create:client_accounts",
    CLIENT_DELETE_ACCOUNTS = "delete:client_accounts",
    CLIENT_UPDATE_ACCOUNTS = "update:client_accounts",
    CLIENT_READ_VIOLATIONS = "read:client_violations",
    CLIENT_CREATE_VIOLATIONS = "create:client_violations",
    CLIENT_DELETE_VIOLATIONS = "delete:client_violations",
    CLIENT_UPDATE_VIOLATIONS = "update:client_violations",
    CLIENT_READ_DOCUMENT = "read:client_document",
    CLIENT_IMPORT_DOCUMENT = "import:client_document",
    CLIENT_DELETE_DOCUMENT = "delete:client_document",
    CLIENT_READ_NOTES = "read:client_notes",
    CLIENT_CREATE_NOTES = "create:client_notes",
    CLIENT_DELETE_NOTES = "delete:client_notes",
    CLIENT_UPDATE_NOTES = "update:client_notes",
    CLIENT_READ_CONVERSATION = "read:client_conversation",
    CLIENT_CREATE_CONVERSATION = "create:client_conversation",

    EMPLOYEE_ACCESS = "read:employee_tab",
    EMPLOYEE_READ = "read:employee",
    EMPLOYEE_READ_NOT_OWN = "read:employee_not_own",
    EMPLOYEE_CREATE = "create:employee",
    EMPLOYEE_DELETE = "delete:employee",
    EMPLOYEE_READ_OVERALL = "read:employee_overall",
    EMPLOYEE_UPDATE_OVERALL = "update:employee_overall",
    EMPLOYEE_READ_CONTACT = "read:employee_contact",
    EMPLOYEE_CREATE_NOTES = "create:employee_notes",
    EMPLOYEE_UPDATE_NOTES = "update:employee_notes",
    EMPLOYEE_DELETE_NOTES = "delete:employee_notes",
    EMPLOYEE_IMPORT_DOCUMENT = "import:employee_document",
    EMPLOYEE_DELETE_DOCUMENT = "delete:employee_document",
    EMPLOYEE_READ_COMMISSIONS = "read:employee_commissions",
    EMPLOYEE_UPDATE_COMMISSIONS = "update:employee_commissions",

    ACTIVITY_ACCESS = "read:activity_tab",

    CAMPAIGN_ACCESS = "read:campaign_tab",
    CAMPAIGN_READ_BULK_MESSAGES = "read:campaign_bulk_messages",
    CAMPAIGN_CREATE_BULK_MESSAGES = "create:campaign_bulk_messages",
    CAMPAIGN_READ = "read:campaign",
    CAMPAIGN_CREATE = "create:campaign",
    CAMPAIGN_DELETE = "delete:campaign",
    CAMPAIGN_UPDATE = "update:campaign",
    CAMPAIGN_READ_TEMPLATES = "read:campaign_templates",
    CAMPAIGN_CREATE_TEMPLATES = "create:campaign_templates",
    CAMPAIGN_DELETE_TEMPLATES = "delete:campaign_templates",
    CAMPAIGN_UPDATE_TEMPLATES = "update:campaign_templates",

    USER_ACCESS = "read:user_tab",
    USER_CREATE = "create:user",
    USER_READ = "read:user",
    USER_UPDATE = "update:user",
    USER_DELETE = "delete:user",
    USER_UPDATE_ROLE_PERMISSIONS = "update:user_role_permissions",
    USER_UPDATE_PASSWORD = "update:user_password",

    CALENDAR_ACCESS = "read:calendar_tab",
    CALENDAR_READ_APPOINTMENTS = "read:calendar_appointments",
    CALENDAR_READ_APPOINTMENTS_OWN = "read:calendar_appointments_own",
    CALENDAR_CREATE_APPOINTMENTS = "create:calendar_appointments",
    CALENDAR_UPDATE_APPOINTMENTS = "update:calendar_appointments",
    CALENDAR_DELETE_APPOINTMENTS = "delete:calendar_appointments",
    CALENDAR_CREATE_APPOINTMENTS_SUPERIOR = "create:calendar_appointments_superior",
    CALENDAR_CREATE_APPOINTMENTS_INFERIOR = "create:calendar_appointments_inferior",
    CALENDAR_READ_APPOINTMENTS_ASSIGNED_BY = "read:calendar_appointments_assigned_by",
    CALENDAR_READ_TASKS = "read:calendar_tasks",
    CALENDAR_READ_TASKS_OWN = "read:calendar_tasks_own",
    CALENDAR_CREATE_TASKS = "create:calendar_tasks",
    CALENDAR_UPDATE_TASKS = "update:calendar_tasks",
    CALENDAR_DELETE_TASKS = "delete:calendar_tasks",
    CALENDAR_CREATE_TASKS_SUPERIOR = "create:calendar_tasks_superior",
    CALENDAR_CREATE_TASKS_INFERIOR = "create:calendar_tasks_inferior",
    CALENDAR_READ_TASKS_ASSIGNED_BY = "read:calendar_tasks_assigned_by",

    SETTINGS_ACCESS = "read:settings_tab",
    SETTINGS_READ_DROPDOWNS = "read:settings_dropdowns",
    SETTINGS_READ_ROUND_ROBIN = "read:settings_round_robin",
    SETTINGS_READ_CREDITORS = "read:settings_creditors",
    SETTINGS_READ_CR_PRICING = "read:settings_cr_pricing",
    SETTINGS_UPDATE_DROPDOWNS = "update:settings_dropdowns",
    SETTINGS_UPDATE_ROUND_ROBIN = "update:settings_round_robin",
    SETTINGS_UPDATE_CREDITORS = "update:settings_creditors",
    SETTINGS_UPDATE_CR_PRICING = "update:settings_cr_pricing",
}

/**
 * ACCESS PERMISSIONS ARRAY
 */
export const ACCESS_PERMISSIONS = [
    PERMISSIONS.LEAD_ACCESS,
    PERMISSIONS.CLIENT_ACCESS,
    PERMISSIONS.EMPLOYEE_ACCESS,
    PERMISSIONS.ACTIVITY_ACCESS,
    PERMISSIONS.CAMPAIGN_ACCESS,
    PERMISSIONS.USER_ACCESS,
    PERMISSIONS.CALENDAR_ACCESS,
    PERMISSIONS.SETTINGS_ACCESS,
];

/**
 * Permissions for Special section
 */
export const SPECIAL_PERMISSIONS = [
    PERMISSIONS.VALIDATE_PROGRAM,
    PERMISSIONS.VALIDATE_SPECIAL_PRICING_PROGRAM,
    PERMISSIONS.READ_PROCESSING_STAGES_TABLE,
];

/**
 * Permissions for LEADS pages
 */
export const LEAD_PERMISSIONS = {
    access: PERMISSIONS.LEAD_ACCESS,
    sections: [
        {
            title: "General",
            permissions: [
                {
                    text: "Access to: ",
                    dropdown_options: [
                        {
                            label: "All Leads",
                            value: PERMISSIONS.LEAD_READ,
                            markAll: true,
                        },
                        {
                            label: "Own Leads",
                            value: PERMISSIONS.LEAD_READ_OWN,
                        },
                    ],
                },
                PERMISSIONS.LEAD_CREATE,
                // PERMISSIONS.LEAD_UPDATE,
                PERMISSIONS.LEAD_DELETE,
                PERMISSIONS.LEAD_IMPORT,
                PERMISSIONS.LEAD_UPDATE_FLAG,
                PERMISSIONS.LEAD_UPDATE_UNFLAG,
                {
                    text: "Update Agent: ",
                    dropdown_options: [
                        {
                            label: "All Leads",
                            value: PERMISSIONS.LEAD_UPDATE_AGENT,
                            markAll: true,
                        },
                        {
                            label: "Own Leads",
                            value: PERMISSIONS.LEAD_UPDATE_AGENT_OWN,
                        },
                    ],
                },
                {
                    text: "Update Real Estate Agent: ",
                    dropdown_options: [
                        {
                            label: "All Leads",
                            value: PERMISSIONS.LEAD_UPDATE_RE_AGENT,
                            markAll: true,
                        },
                        {
                            label: "Own Leads",
                            value: PERMISSIONS.LEAD_UPDATE_RE_AGENT_OWN,
                        },
                    ],
                },
                {
                    text: "Update Funding Agent: ",
                    dropdown_options: [
                        {
                            label: "All Leads",
                            value: PERMISSIONS.LEAD_UPDATE_FUNDING_AGENT,
                            markAll: true,
                        },
                        {
                            label: "Own Leads",
                            value: PERMISSIONS.LEAD_UPDATE_FUNDING_AGENT_OWN,
                        },
                    ],
                },
                {
                    text: "Update Loan Officer: ",
                    dropdown_options: [
                        {
                            label: "All Leads",
                            value: PERMISSIONS.LEAD_UPDATE_LOAN_OFFICER,
                            markAll: true,
                        },
                        {
                            label: "Own Leads",
                            value: PERMISSIONS.LEAD_UPDATE_LOAN_OFFICER_OWN,
                        },
                    ],
                },
                PERMISSIONS.LEAD_DETAILS,
                PERMISSIONS.LEAD_UPDATE_CONVERT,
            ],
        },
        {
            title: "Overall",
            permissions: [
                PERMISSIONS.LEAD_READ_OVERALL,
                PERMISSIONS.LEAD_UPDATE_OVERALL,
                PERMISSIONS.LEAD_CREATE_CREDIT_SCORES,
                PERMISSIONS.LEAD_UPDATE_CREDIT_SCORES,
                PERMISSIONS.LEAD_DELETE_CREDIT_SCORES,
            ],
        },
        {
            title: "Programs",
            permissions: [
                PERMISSIONS.LEAD_READ_PROGRAM,
                PERMISSIONS.LEAD_CREATE_PROGRAM,
                // PERMISSIONS.LEAD_UPDATE_PROGRAM_CONTRACT_CLOSED,
                // PERMISSIONS.LEAD_UPDATE_PROGRAM_STATUS,
                // PERMISSIONS.LEAD_READ_PROGRAM_AGENT_FEE,
                // PERMISSIONS.LEAD_READ_PROGRAM_PRICE,
                // PERMISSIONS.LEAD_READ_PROGRAM_INFO,
                // PERMISSIONS.LEAD_DELETE_PROGRAM,
            ],
        },
        {
            title: "Financial Disclosure",
            permissions: [PERMISSIONS.LEAD_READ_FINANCIAL, PERMISSIONS.LEAD_UPDATE_FINANCIAL],
        },
        {
            title: "Documents",
            permissions: [
                PERMISSIONS.LEAD_READ_DOCUMENT,
                PERMISSIONS.LEAD_IMPORT_DOCUMENT,
                PERMISSIONS.LEAD_DELETE_DOCUMENT,
            ],
        },
        {
            title: "Notes",
            permissions: [
                PERMISSIONS.LEAD_READ_NOTES,
                PERMISSIONS.LEAD_CREATE_NOTES,
                PERMISSIONS.LEAD_UPDATE_NOTES,
                PERMISSIONS.LEAD_DELETE_NOTES,
            ],
        },
        {
            title: "Conversations",
            permissions: [PERMISSIONS.LEAD_READ_CONVERSATION, PERMISSIONS.LEAD_CREATE_CONVERSATION],
        },
    ],
};

/**
 * Permissions for CLIENTS pages
 */
export const CLIENT_PERMISSIONS = {
    access: PERMISSIONS.CLIENT_ACCESS,
    sections: [
        {
            title: "General",
            permissions: [
                {
                    text: "Access to: ",
                    dropdown_options: [
                        {
                            label: "All Clients",
                            value: PERMISSIONS.CLIENT_READ,
                            markAll: true,
                        },
                        {
                            label: "Own Clients",
                            value: PERMISSIONS.CLIENT_READ_OWN,
                        },
                        {
                            label: "Bankruptcy Clients",
                            value: PERMISSIONS.CLIENT_READ_BANKRUPTCY,
                        },
                    ],
                },
                PERMISSIONS.CLIENT_CREATE,
                // PERMISSIONS.CLIENT_UPDATE,
                PERMISSIONS.CLIENT_DELETE,
                PERMISSIONS.CLIENT_IMPORT,
                {
                    text: "Update Agent: ",
                    dropdown_options: [
                        {
                            label: "All Clients",
                            value: PERMISSIONS.CLIENT_UPDATE_AGENT,
                            markAll: true,
                        },
                        {
                            label: "Own Clients",
                            value: PERMISSIONS.CLIENT_UPDATE_AGENT_OWN,
                        },
                    ],
                },
                {
                    text: "Update Real Estate Agent: ",
                    dropdown_options: [
                        {
                            label: "All Clients",
                            value: PERMISSIONS.CLIENT_UPDATE_RE_AGENT,
                            markAll: true,
                        },
                        {
                            label: "Own Clients",
                            value: PERMISSIONS.CLIENT_UPDATE_RE_AGENT_OWN,
                        },
                    ],
                },
                {
                    text: "Update Funding Agent: ",
                    dropdown_options: [
                        {
                            label: "All Clients",
                            value: PERMISSIONS.CLIENT_UPDATE_FUNDING_AGENT,
                            markAll: true,
                        },
                        {
                            label: "Own Clients",
                            value: PERMISSIONS.CLIENT_UPDATE_FUNDING_AGENT_OWN,
                        },
                    ],
                },
                {
                    text: "Update Loan Officer: ",
                    dropdown_options: [
                        {
                            label: "All Clients",
                            value: PERMISSIONS.CLIENT_UPDATE_LOAN_OFFICER,
                            markAll: true,
                        },
                        {
                            label: "Own Clients",
                            value: PERMISSIONS.CLIENT_UPDATE_LOAN_OFFICER_OWN,
                        },
                    ],
                },
                PERMISSIONS.CLIENT_UPDATE_FLAG,
                PERMISSIONS.CLIENT_UPDATE_UNFLAG,
                PERMISSIONS.CLIENT_ACTIVITY,
                PERMISSIONS.CLIENT_DETAILS,
                PERMISSIONS.CLIENT_PRODUCTION,
            ],
        },
        {
            title: "Overall",
            permissions: [
                PERMISSIONS.CLIENT_READ_OVERALL,
                PERMISSIONS.CLIENT_UPDATE_OVERALL,
                PERMISSIONS.CLIENT_CREATE_CREDIT_SCORES,
                PERMISSIONS.CLIENT_UPDATE_CREDIT_SCORES,
                PERMISSIONS.CLIENT_DELETE_CREDIT_SCORES,
            ],
        },
        {
            title: "Programs",
            permissions: [
                PERMISSIONS.CLIENT_READ_PROGRAM,
                PERMISSIONS.CLIENT_CREATE_PROGRAM,
                PERMISSIONS.CLIENT_UPDATE_PROGRAM_CONTRACT_CLOSED,
                PERMISSIONS.CLIENT_UPDATE_PROGRAM_STATUS,
                PERMISSIONS.CLIENT_READ_PROGRAM_AGENT_FEE,
                PERMISSIONS.CLIENT_READ_PROGRAM_PRICE,
                PERMISSIONS.CLIENT_READ_PROGRAM_INFO,
                PERMISSIONS.CLIENT_DELETE_PROGRAM,
            ],
        },
        {
            title: "Bankruptcy",
            permissions: [
                PERMISSIONS.CLIENT_READ_BANKRUPTCY_TAB,
                PERMISSIONS.CLIENT_UPDATE_BANKRUPTCY_TAB,
            ],
        },
        {
            title: "Payment",
            permissions: [
                PERMISSIONS.CLIENT_READ_PAYMENT,
                PERMISSIONS.CLIENT_UNLOCK_PAYMENT_METHODS,
                PERMISSIONS.CLIENT_UPDATE_PAYMENT_METHODS,
                PERMISSIONS.CLIENT_READ_PAYMENT_TRANSACTIONS,
                PERMISSIONS.CLIENT_CREATE_PAYMENT_TRANSACTIONS,
                PERMISSIONS.CLIENT_UPDATE_PAYMENT_TRANSACTIONS,
                PERMISSIONS.CLIENT_READ_PAYMENT_RE_TRANSACTIONS,
                PERMISSIONS.CLIENT_CREATE_PAYMENT_RE_TRANSACTIONS,
                PERMISSIONS.CLIENT_UPDATE_PAYMENT_RE_TRANSACTIONS,
                PERMISSIONS.CLIENT_DELETE_PAYMENT_RE_TRANSACTIONS,
                PERMISSIONS.CLIENT_UPDATE_PAYMENT_INFO,
                PERMISSIONS.CLIENT_READ_PAYMENT_FEES,
                PERMISSIONS.CLIENT_CREATE_PAYMENT_FEES,
                PERMISSIONS.CLIENT_UPDATE_PAYMENT_FEES,
                PERMISSIONS.CLIENT_DELETE_PAYMENT_FEES,
                PERMISSIONS.CLIENT_READ_PAYMENT_FEES_TRANSACTIONS,
                PERMISSIONS.CLIENT_CREATE_PAYMENT_FEES_TRANSACTIONS,
                PERMISSIONS.CLIENT_UPDATE_PAYMENT_FEES_TRANSACTIONS,
            ],
        },
        {
            title: "Financial Disclosure",
            permissions: [PERMISSIONS.CLIENT_READ_FINANCIAL, PERMISSIONS.CLIENT_UPDATE_FINANCIAL],
        },
        {
            title: "Processing",
            permissions: [
                PERMISSIONS.CLIENT_READ_PROCESSING,
                PERMISSIONS.CLIENT_UPDATE_PROCESSING,
                PERMISSIONS.CLIENT_READ_PROCESSING_PROCESS,
                PERMISSIONS.CLIENT_CREATE_PROCESSING_PROCESS,
                PERMISSIONS.CLIENT_DELETE_PROCESSING_PROCESS,
                PERMISSIONS.CLIENT_UPDATE_PROCESSING_PROCESS,
                PERMISSIONS.CLIENT_READ_PROCESSING_PLATFORM_ACCESS,
                PERMISSIONS.CLIENT_CREATE_PROCESSING_PLATFORM_ACCESS,
                PERMISSIONS.CLIENT_DELETE_PROCESSING_PLATFORM_ACCESS,
                PERMISSIONS.CLIENT_UPDATE_PROCESSING_PLATFORM_ACCESS,
            ],
        },
        {
            title: "Funding",
            permissions: [
                PERMISSIONS.CLIENT_READ_FUNDING,
                PERMISSIONS.CLIENT_READ_FUNDING_CREDIT_LINES,
                PERMISSIONS.CLIENT_CREATE_FUNDING_CREDIT_LINES,
                PERMISSIONS.CLIENT_UPDATE_FUNDING_CREDIT_LINES,
                PERMISSIONS.CLIENT_DELETE_FUNDING_CREDIT_LINES,
                PERMISSIONS.CLIENT_READ_FUNDING_MORTGAGE,
                PERMISSIONS.CLIENT_CREATE_FUNDING_MORTGAGE,
                PERMISSIONS.CLIENT_UPDATE_FUNDING_MORTGAGE,
                PERMISSIONS.CLIENT_DELETE_FUNDING_MORTGAGE,
                PERMISSIONS.CLIENT_READ_FUNDING_BANK_ACCESS,
                PERMISSIONS.CLIENT_CREATE_FUNDING_BANK_ACCESS,
                PERMISSIONS.CLIENT_UPDATE_FUNDING_BANK_ACCESS,
                PERMISSIONS.CLIENT_DELETE_FUNDING_BANK_ACCESS,
            ]
        },
        {
            title: "Tradelines",
            permissions: [
                PERMISSIONS.CLIENT_READ_TRADELINES,
                PERMISSIONS.CLIENT_UPDATE_TRADELINES,
                PERMISSIONS.CLIENT_READ_TRADELINES_TRADELINE,
                PERMISSIONS.CLIENT_CREATE_TRADELINES_TRADELINE,
                PERMISSIONS.CLIENT_DELETE_TRADELINES_TRADELINE,
                PERMISSIONS.CLIENT_UPDATE_TRADELINES_TRADELINE,
            ],
        },
        {
            title: "Settlements",
            permissions: [
                PERMISSIONS.CLIENT_READ_SETTLEMENTS,
                PERMISSIONS.CLIENT_READ_SETTLEMENTS_SETTLEMENT,
                PERMISSIONS.CLIENT_CREATE_SETTLEMENTS_SETTLEMENT,
                PERMISSIONS.CLIENT_DELETE_SETTLEMENTS_SETTLEMENT,
                PERMISSIONS.CLIENT_UPDATE_SETTLEMENTS_SETTLEMENT,
                PERMISSIONS.CLIENT_CREATE_SETTLEMENTS_NOTIFICATION,
                PERMISSIONS.CLIENT_READ_SETTLEMENTS_CREDIT_CARD,
                PERMISSIONS.CLIENT_CREATE_SETTLEMENTS_CREDIT_CARD,
                PERMISSIONS.CLIENT_DELETE_SETTLEMENTS_CREDIT_CARD,
                PERMISSIONS.CLIENT_UPDATE_SETTLEMENTS_CREDIT_CARD,
            ],
        },
        {
            title: "Accounts",
            permissions: [
                PERMISSIONS.CLIENT_READ_ACCOUNTS,
                PERMISSIONS.CLIENT_CREATE_ACCOUNTS,
                PERMISSIONS.CLIENT_DELETE_ACCOUNTS,
                PERMISSIONS.CLIENT_UPDATE_ACCOUNTS,
            ],
        },
        {
            title: "Violations",
            permissions: [
                PERMISSIONS.CLIENT_READ_VIOLATIONS,
                PERMISSIONS.CLIENT_CREATE_VIOLATIONS,
                PERMISSIONS.CLIENT_DELETE_VIOLATIONS,
                PERMISSIONS.CLIENT_UPDATE_VIOLATIONS,
            ],
        },
        {
            title: "Documents",
            permissions: [
                PERMISSIONS.CLIENT_READ_DOCUMENT,
                PERMISSIONS.CLIENT_IMPORT_DOCUMENT,
                PERMISSIONS.CLIENT_DELETE_DOCUMENT,
            ],
        },
        {
            title: "Notes",
            permissions: [
                PERMISSIONS.CLIENT_READ_NOTES,
                PERMISSIONS.CLIENT_CREATE_NOTES,
                PERMISSIONS.CLIENT_DELETE_NOTES,
                PERMISSIONS.CLIENT_UPDATE_NOTES,
            ],
        },
        {
            title: "Conversations",
            permissions: [
                PERMISSIONS.CLIENT_READ_CONVERSATION,
                PERMISSIONS.CLIENT_CREATE_CONVERSATION,
            ],
        },
    ],
};

/**
 * Permissions for EMPLOYEES pages
 */
export const EMPLOYEE_PERMISSIONS = [
    {
        text: "Access to: ",
        dropdown_options: [
            {
                label: "All Employees",
                value: PERMISSIONS.EMPLOYEE_READ,
                markAll: true,
            },
            {
                label: "All but own",
                value: PERMISSIONS.EMPLOYEE_READ_NOT_OWN,
            },
        ],
    },
    PERMISSIONS.EMPLOYEE_CREATE,
    PERMISSIONS.EMPLOYEE_DELETE,
    PERMISSIONS.EMPLOYEE_READ_OVERALL,
    PERMISSIONS.EMPLOYEE_UPDATE_OVERALL,
    PERMISSIONS.EMPLOYEE_READ_CONTACT,
    PERMISSIONS.EMPLOYEE_CREATE_NOTES,
    PERMISSIONS.EMPLOYEE_UPDATE_NOTES,
    PERMISSIONS.EMPLOYEE_DELETE_NOTES,
    PERMISSIONS.EMPLOYEE_IMPORT_DOCUMENT,
    PERMISSIONS.EMPLOYEE_DELETE_DOCUMENT,
    PERMISSIONS.EMPLOYEE_READ_COMMISSIONS,
    PERMISSIONS.EMPLOYEE_UPDATE_COMMISSIONS,
];

/**
 * Permissions for CAMPAIGNS pages
 */
export const CAMPAIGNS_PERMISSIONS = [
    PERMISSIONS.CAMPAIGN_READ_BULK_MESSAGES,
    PERMISSIONS.CAMPAIGN_CREATE_BULK_MESSAGES,
    PERMISSIONS.CAMPAIGN_READ,
    PERMISSIONS.CAMPAIGN_CREATE,
    PERMISSIONS.CAMPAIGN_DELETE,
    PERMISSIONS.CAMPAIGN_UPDATE,
    PERMISSIONS.CAMPAIGN_READ_TEMPLATES,
    PERMISSIONS.CAMPAIGN_CREATE_TEMPLATES,
    PERMISSIONS.CAMPAIGN_DELETE_TEMPLATES,
    PERMISSIONS.CAMPAIGN_UPDATE_TEMPLATES,
];

/**
 * Permissions for USERS pages
 */
export const USERS_PERMISSIONS = [
    PERMISSIONS.USER_READ,
    PERMISSIONS.USER_CREATE,
    PERMISSIONS.USER_UPDATE,
    PERMISSIONS.USER_DELETE,
    PERMISSIONS.USER_UPDATE_ROLE_PERMISSIONS,
    PERMISSIONS.USER_UPDATE_PASSWORD,
];

/**
 * Permissions for CALENDAR pages
 */
export const CALENDAR_PERMISSIONS = [
    {
        text: "View: ",
        dropdown_options: [
            {
                label: "All Appointments",
                value: PERMISSIONS.CALENDAR_READ_APPOINTMENTS,
                markAll: true,
            },
            {
                label: "Own Appointments",
                value: PERMISSIONS.CALENDAR_READ_APPOINTMENTS_OWN,
            },
        ],
    },
    PERMISSIONS.CALENDAR_CREATE_APPOINTMENTS,
    PERMISSIONS.CALENDAR_UPDATE_APPOINTMENTS,
    PERMISSIONS.CALENDAR_DELETE_APPOINTMENTS,
    PERMISSIONS.CALENDAR_CREATE_APPOINTMENTS_SUPERIOR,
    PERMISSIONS.CALENDAR_CREATE_APPOINTMENTS_INFERIOR,
    PERMISSIONS.CALENDAR_READ_APPOINTMENTS_ASSIGNED_BY,
    {
        text: "View: ",
        dropdown_options: [
            {
                label: "All Tasks",
                value: PERMISSIONS.CALENDAR_READ_TASKS,
                markAll: true,
            },
            {
                label: "Own Tasks",
                value: PERMISSIONS.CALENDAR_READ_TASKS_OWN,
            },
        ],
    },
    PERMISSIONS.CALENDAR_CREATE_TASKS,
    PERMISSIONS.CALENDAR_UPDATE_TASKS,
    PERMISSIONS.CALENDAR_DELETE_TASKS,
    PERMISSIONS.CALENDAR_CREATE_TASKS_SUPERIOR,
    PERMISSIONS.CALENDAR_CREATE_TASKS_INFERIOR,
    PERMISSIONS.CALENDAR_READ_TASKS_ASSIGNED_BY,
];

/**
 * Permissions for SETTINGS Pages
 */
export const SETTINGS_PERMISSIONS = [
    PERMISSIONS.SETTINGS_READ_DROPDOWNS,
    PERMISSIONS.SETTINGS_READ_ROUND_ROBIN,
    PERMISSIONS.SETTINGS_READ_CREDITORS,
    PERMISSIONS.SETTINGS_READ_CR_PRICING,
    PERMISSIONS.SETTINGS_UPDATE_DROPDOWNS,
    PERMISSIONS.SETTINGS_UPDATE_ROUND_ROBIN,
    PERMISSIONS.SETTINGS_UPDATE_CREDITORS,
    PERMISSIONS.SETTINGS_UPDATE_CR_PRICING,
];

/**
 * Mapper from permission name in auth0 to text to show on user's page
 */
export const PERMISSION_TITLE_MAPPER: any = {
    "validate:program": "Validate Programs",
    "validate:special_pricing_program": "Validate Special Pricing Programs",
    "read:processing_stages": "Read Processing Stages Table",
    "read:lead / read:lead_own": "View All Leads // View Own Leads (Based on Role)",
    "create:lead": "Create New Leads",
    "delete:lead": "Delete Leads",
    "create:lead_import": "Import Leads",
    "update:lead_flag": "Red Flag Leads on Leads",
    "update:lead_unflag": "Remove flagged status on Leads",
    "update:lead_agent": "Edit Lead's Agent Assigned",
    "update:lead_re_agent": "Update Lead's Real Estate Agent",
    "update:lead_funding_agent": "Update Lead's Funding Agent",
    "update:lead_loan_officer": "Update Lead's Loan Officer",
    "read:lead_details": 'View "View Details" Leads Table',
    "update:lead_convert": "Convert Lead to a Client",
    "read:lead_overall": "View Overall's Lead Tab",
    "update:lead_overall": "Edit Overall's Lead Tab Information",
    "create:lead_credit_scores": "Create New Credit Score in Lead",
    "update:lead_credit_scores": "Edit Credit Scores in Lead",
    "delete:lead_credit_scores": "Delete Credit Scores in Lead",
    "read:lead_program": "View Programs' Lead Tab",
    "create:lead_program": "Create Lead's Program ",
    "update:lead_program_contract_closed": "Edit Contract Closed in Leads Programs",
    "update:lead_program_status": "Edit Status in Leads Programs",
    "read:lead_program_agent_fee": "View Agent Fee in Leads Programs",
    "read:lead_program_price": "View Price in Leads Programs",
    "read:lead_program_info": "View Program Info in Leads Programs",
    "delete:lead_program": "Delete Leads Programs ",
    "read:lead_financial": "View Financial Disclousure's Lead Tab",
    "update:lead_financial": "Edit Financial Disclousure's Lead Tab",
    "read:lead_document": "View Documents' Lead Tab",
    "import:lead_document": "Upload Documents in Leads",
    "delete:lead_document": "Delete Documents in Leads",
    "read:lead_notes": "View Notes' Lead Tab",
    "create:lead_notes": "Create Notes in Leads",
    "update:lead_notes": "Edit Notes in Leads",
    "delete:lead_notes": "Delete Notes on Leads",
    "read:lead_conversation": "View Messages in Leads",
    "create:lead_conversation": "Send Messages in Leads",
    "create:client": "Create New Clients",
    "delete:client": "Delete Clients",
    "create:client_import": "Import Clients",
    "update:client_flag": "Red Flag Clients",
    "update:client_unflag": "Remove flagged status in Clients",
    "update:client_agent": "Edit Agent's Client",
    "update:client_re_agent": "Update Client's Real Estate Agent",
    "update:client_funding_agent": "Update Client's Funding Agent",
    "update:client_loan_officer": "Update Client's Loan Officer",
    "read:client_activity": 'View "Acitivity Supervision" Client Table',
    "read:client_details": 'View "Client Details" Client Table',
    "read:client_production": 'View "Production Audit" Client Table',
    "read:client_overall": "View Overall's Client Tab",
    "update:client_overall": "Edit Overall's Client Tab",
    "create:client_credit_scores": "Create New Credit Score in Client",
    "update:client_credit_scores": "Edit Credit Scores in Client",
    "delete:client_credit_scores": "Delete Credit Scores in Client",
    "read:client_program": "View Programs' Client Tab",
    "create:client_program": "Create Programs in Clients Tab",
    "update:client_program_contract_closed": "Edit Contract Closed in Clients Programs",
    "update:client_program_status": "Edit Status in Clients Programs",
    "read:client_program_agent_fee": "View Agent Fee in Clients Programs",
    "read:client_program_price": "View Price in Clients Programs",
    "read:client_program_info": "View Program Info in Clients Programs",
    "delete:client_program": "Delete Clients' Programs ",
    "read:client_bankruptcy_tab": "View Bankruptcy's Client Tab",
    "update:client_bankruptcy_tab": "Edit Bankruptcy's Client Tab",
    "read:client_payment": "View Payment's Client Tab",
    "unlock:client_payment_methods": "View Payment Methods on Payment's Tab",
    "update:client_payment_methods": "Edit Payment Methods on Payment's Tab",
    "read:client_payment_transactions": "View Client Transactions",
    "create:client_payment_transactions": "Create Client Transactions",
    "update:client_payment_transactions": "Edit Client Transactions",
    "read:client_payment_re_transactions": "View Client Real Estate Transactions",
    "create:client_payment_re_transactions": "Create Client Real Estate Transactions",
    "update:client_payment_re_transactions": "Edit Client Real Estate Transactions",
    "delete:client_payment_re_transactions": "Delete Client Real Estate Transactions",
    "update:client_payment_info": "Edit Client Payment Information",
    "read:client_payment_fees": "View Client Fees",
    "create:client_payment_fees": "Create Client Fees",
    "update:client_payment_fees": "Edit Client Fees",
    "delete:client_payment_fees": "Delete Client Fees",
    "read:client_payment_fees_transactions": "View Client Fees Transactions",
    "create:client_payment_fees_transactions": "Create Client Fees Transactions",
    "update:client_payment_fees_transactions": "Edit Client Fees Transactions",
    "read:client_financial": "View Financial Disclousure's Client Tab",
    "update:client_financial": "Edit Financial Disclousure's Client Tab",
    "read:client_processing": "View Processing's Client Tab",
    "update:client_processing": "Edit Processing's Client Tab",
    "read:client_processing_process": "View Client Processes",
    "create:client_processing_process": "Create Client Processes",
    "delete:client_processing_process": "Delete Client Processes",
    "update:client_processing_process": "Edit Client Processes",
    "read:client_processing_platform_access": "View Client Platforms Access",
    "create:client_processing_platform_access": "Create Client Platforms Access",
    "delete:client_processing_platform_access": "Delete Client Platforms Access",
    "update:client_processing_platform_access": "Update Client Platforms Access",
    "read:client_funding": "View Funding's Client Tab",
    "read:client_funding_credit_lines": "View Client Credit Lines",
    "create:client_funding_credit_lines": "Create Client Credit Lines",
    "update:client_funding_credit_lines": "Edit Client Credit Lines",
    "delete:client_funding_credit_lines": "Delete Client Credit Lines",
    "read:client_funding_mortgage": "View Client Mortgages",
    "create:client_funding_mortgage": "Create Client Mortgages",
    "delete:client_funding_mortgage": "Delete Client Mortgages",
    "update:client_funding_mortgage": "Edit Client Mortgages",
    "read:client_funding_bank_access": "View Client Bank Accesses",
    "create:client_funding_bank_access": "Create Client Bank Accesses",
    "delete:client_funding_bank_access": "Delete Client Bank Accesses",
    "update:client_funding_bank_access": "Edit Client Bank Accesses",
    "read:client_tradelines": "View Tradelines' Client Trab",
    "update:client_tradelines": "Edit Tradelines' Client Tab",
    "read:client_tradelines_tradeline": "View Client Tradelines",
    "create:client_tradelines_tradeline": "Create Client Tradelines",
    "delete:client_tradelines_tradeline": "Delete Client Tradelines",
    "update:client_tradelines_tradeline": "Edit Client Tradelines",
    "read:client_settlements": "View Settlements' Client Tab",
    "read:client_settlements_settlement": "View Client Settlements",
    "create:client_settlements_settlement": "Create Client Settlements",
    "delete:client_settlements_settlement": "Delete Client Settlements",
    "update:client_settlements_settlement": "Edit Client Settlements",
    "create:client_settlements_notification": "Notify Client Settlement",
    "read:client_settlements_credit_card": "View Client Credit Cards",
    "create:client_settlements_credit_card": "Create Client Credit Cards",
    "delete:client_settlements_credit_card": "Delete Client Credit Cards",
    "update:client_settlements_credit_card": "Edit Client Credit Cards",
    "read:client_accounts": "View Accounts' Client Tab",
    "create:client_accounts": "Create Client Accounts",
    "delete:client_accounts": "Delete Client Accounts",
    "update:client_accounts": "Edit Client Accounts",
    "read:client_violations": "View Violations' Client Tab",
    "create:client_violations": "Create Client Violations",
    "delete:client_violations": "Delete Client Violations",
    "update:client_violations": "Edit Client Violations",
    "read:client_document": "View Documents' Client Tab",
    "import:client_document": "Upload Documents in Clients",
    "delete:client_document": "Delete Documents in Clients",
    "read:client_notes": "View Notes' Client Tab",
    "create:client_notes": "Create Notes in Clients",
    "update:client_notes": "Edit Notes in Clients",
    "delete:client_notes": "Delete Notes on Clients",
    "read:client_conversation": "View Messages in Clients",
    "create:client_conversation": "Send Messages in Clients",
    "create:employee": "Create New Employees",
    "delete:employee": "Delete Employees",
    "read:employee_overall": "View Overall's Employee Tab",
    "update:employee_overall": "Edit Overall's Employee Tab",
    "read:employee_contact": "View Contact's Employee Tab",
    "create:employee_notes": "Create Notes in Employees",
    "update:employee_notes": "Edit Notes in Employees",
    "import:employee_document": "Upload Documents in Employee",
    "delete:employee_notes": "Delete Notes in Employees",
    "delete:employee_document": "Delete Documents in Employee",
    "read:employee_commissions": "View Commissions' Employee Tab",
    "update:employee_commissions": "Edit Commissions' Employee Tab",
    "create:campaign_bulk_messages": "Create Bulk Messages",
    "read:campaign_bulk_messages": "View Bulk Messages",
    "read:campaign": "View Campaigns",
    "create:campaign": "Create Campaigns",
    "update:campaign": "Edit Campaigns",
    "delete:campaign": "Delete Campaigns",
    "read:campaign_templates": "View Campaign Templates",
    "update:campaign_templates": "Edit Campaign Templates",
    "create:campaign_templates": "Create Campaign Templates",
    "delete:campaign_templates": "Delete Campaign Templates",
    "create:user": "Create New Users",
    "read:user": "View Users",
    "update:user": "Edit Users",
    "delete:user": "Delete Users",
    "update:user_role_permissions": "Edit Users' Roles and Permissions",
    "update:user_password": "Edit Users' Passwords",
    "create:calendar_appointments": "Create Appointments",
    "update:calendar_appointments": "Edit Appointments",
    "delete:calendar_appointments": "Delete Appointments",
    "create:calendar_appointments_other": "Assign Appointments to Others",
    "create:calendar_appointments_superior": "Assign Appointments to Superior Roles",
    "create:calendar_appointments_inferior": "Assign Appointments to Inferior Roles",
    "read:calendar_appointments_assigned_by": "View Appointments Assigned by",
    "create:calendar_tasks": "Create Tasks",
    "update:calendar_tasks": "Edit Tasks",
    "delete:calendar_tasks": "Delete Tasks",
    "create:calendar_tasks_other": "Assign Tasks to Others",
    "create:calendar_tasks_superior": "Assign Tasks to Superior Roles",
    "create:calendar_tasks_inferior": "Assign Tasks to Inferior Roles",
    "read:calendar_tasks_assigned_by": "View Tasks Assigned by",
    "read:settings_dropdowns": "View Settings Dropdowns",
    "read:settings_round_robin": "View Settings Round Robin",
    "read:settings_creditors": "View Settings Creditors",
    "read:settings_cr_pricing": "View Settings CR Pricing",
    "update:settings_dropdowns": "Edit Settings Dropdowns",
    "update:settings_round_robin": "Edit Settings Round Robin",
    "update:settings_creditors": "Edit Settings Creditors",
    "update:settings_cr_pricing": "Edit Settings CR Pricing",
};
