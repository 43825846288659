import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import useSWR, { SWRResponse } from "swr";
import { fetcherGet } from "../utils";

export default function useGetSWR<Type>(
    url: string | null,
    options: any
    // body?: string
): SWRResponse<Type, string> {
    const [token, setToken] = useState("");
    const { getAccessTokenSilently } = useAuth0();

    /**
     * The mounted variable is used to stop memory leak issues.
     *
     */
    useEffect(() => {
        let mounted = true;
        const getToken = async () => {
            const newToken = await getAccessTokenSilently();
            if (mounted) setToken(newToken);
        };
        getToken();

        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const useSWRBody = options.external ? [url, ""] : token && url ? [url, token] : null;
    return useSWR(useSWRBody, fetcherGet, options);
}
