import { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { API } from "../../../config";
import {
    addProgramPopup,
    openProgram,
    programsToValidate,
    validatingProgram,
} from "../../../config/recoil/atoms";
import {
    formatProgramValidation,
    getInfoForProgram,
    needsValidation,
    newProgram,
} from "../../../helpers/Programs";
import { useHandleError, useUpdate } from "../../../hooks";
import { CloseIcon } from "../../../icons";
import { IProgramValidation } from "../../../types";
import { CreateProgramPopup } from "../../../types/ClientProgram";
import { NOTIFY_CLIENT_SAVED, openNotification } from "../../../utils";

import {
    checkSocketConnected,
    disconnectSocket,
    finishProgramValidation,
    processProgramValidation,
    stopProcessProgramValidation,
    subscribe,
    unsubscribe,
    validateProgramValidation,
} from "../../../utils/socket";
import { CancelProgramPopup } from "../../../views/Popups/Programs/CancelProgramPopup";
import { ValidateProgramPopup } from "../../../views/Popups/Programs/ValidateProgramPopup";

interface INotification {
    title: string;
    subtitle: string;
    onClose: () => void;
    onValidate: () => void;
    onRead: () => void;
    buttonText: string;
    specialPrice: boolean;
}

const Notification = ({
    title,
    subtitle,
    onClose,
    onValidate,
    buttonText,
    onRead,
    specialPrice,
}: INotification) => {
    return (
        <div
            className={`relative flex flex-col animate-fadeInUp old ${specialPrice ? "bg-color14" : "bg-color2"
                } p-5 sm:w-[400px] w-[300px] rounded-md items-left justify-center`}
        >
            <p className="pb-0 font-bold text-lg">{title}</p>
            <p>
                {subtitle}
                {specialPrice && <span className="font-bold">{" (Special Price)"}</span>}
            </p>
            <div>
                <button className="button-crm py-1 px-3 mt-2" onClick={onRead}>
                    {buttonText}
                </button>
            </div>
            <div className="absolute right-2 top-2">
                <CloseIcon
                    className="fill-current text-color6 absolute right-0 top-0 mr-1 mt-1 cursor-pointer"
                    onClick={onClose}
                />
            </div>
        </div>
    );
};
const checkPrice = (priceError: string, data: any, setPriceError: Function, program: any) => {
    if (program.status === "waiting") {
        if (
            needsValidation(data.name, data.special_pricing, data.club_type) &&
            !data.special_pricing
        ) {
            setPriceError("");
            return false;
        }
    }
    if (data.name.includes("Funding - ") || data.name === 'Funding') {
        setPriceError("");
        return false;
    }
    if (priceError) {
        return true;
    }
    if (!data.price) {
        setPriceError("* Required");
        return true;
    }
    const cost = parseFloat(data.cost);
    const price = parseFloat(data.price);
    if (price < cost) {
        console.log("price < cost");
        setPriceError("* Price cannot be lower than cost.");
        return true;
    }
    setPriceError("");
    return false;
};

interface iProps {
    canValidate: boolean;
    canAddProgram: boolean;
    canValidateSpecialPricing: boolean;
}
export default function RealtimeNotifications({
    canValidate,
    canAddProgram,
    canValidateSpecialPricing,
}: iProps) {
    const [priceError, setPriceError] = useState("");
    const location = useLocation();
    const [programsToValidateA, setProgramsToValidateA] = useRecoilState(programsToValidate);
    const setAddProgramPopup = useSetRecoilState(addProgramPopup);
    const [openedProgram, setOpenedProgram] = useRecoilState(openProgram);
    const [validating, setValidating] = useRecoilState(validatingProgram);
    const [validatingAux, setValidatingAux] = useState(validating);
    const [addProgram, setAddProgram] = useState<boolean>(false);
    const [cancelProgram, setCancelProgram] = useState<boolean>(false);
    const idRef = useRef();
    idRef.current = openedProgram;
    /**
     * Subscribe to the events we need
     */
    const connect = () => {
        if (!checkSocketConnected()) return;

        // Role check to see in which rooms we subscribe
        // Subscribe to get the initial validations array
        // if (canAddProgram && canValidate && canValidateSpecialPricing)
        //     subscribe("all-program-validation-sp", initPrograms);
        // else if (canAddProgram && canValidate && !canValidateSpecialPricing)
        //     subscribe("all-program-validation", initPrograms);
        // else if (!canAddProgram && canValidate && canValidateSpecialPricing)
        //     subscribe("only-program-validation-sp", initPrograms);
        // else if (!canAddProgram && canValidate && !canValidateSpecialPricing)
        //     subscribe("only-program-validation", initPrograms);
        // else if (canAddProgram && !canValidate) subscribe("only-program-validated", initPrograms);
        if (canValidate) subscribe("all-program-validation-sp", initPrograms);

        if (canValidate) {
            subscribe("new-program-validation", onNewProgramValidation);
            subscribe("remove-program-validation", onRemoveProgramValidation);
            subscribe("process-program-validation", onProcessProgramValidation);
            subscribe("stop-process-program-validation", onStopProcessProgramValidation);
        }
        if (canValidateSpecialPricing) {
            subscribe("new-special-pricing-validation", onNewProgramValidation);
        }
        // Check for agents that can receive program validations
        if (canAddProgram) {
            subscribe("program-validated", onNewProgramValidation);
        }
    };

    const onFocus = () => {
        disconnect();
        // disconnectSocket();
        connect();
    };

    useEffect(() => {
        const onFocusFunction = window.addEventListener("focus", onFocus);
        return onFocusFunction;
    }, []);

    useEffect(() => {
        setValidatingAux(validating);
    }, [validating]);

    /**
     * New Program to validate
     * @param msg
     */
    const onNewProgramValidation = (msg: IProgramValidation) => {
        setProgramsToValidateA((prev) => {
            if (prev.findIndex((v) => v.id === msg.id) > 0) return prev;
            return [...prev, { ...msg, show: true }];
        });
        setValidatingAux((prev: boolean) => {
            if (prev && msg.status === "validated") {
                readValidatedNotification({ ...msg });
                setAddProgramPopup(false);
                setValidating(false);
                return false;
            }
            return prev;
        });
    };

    const initPrograms = (msg: IProgramValidation[]) => {
        setProgramsToValidateA((prev) => [
            ...new Set([...prev, ...msg.map((m) => ({ ...m, show: true }))]),
        ]);
    };

    const onRemoveProgramValidation = (msg: IProgramValidation) => {
        deleteNotification(msg.id);
    };

    const onProcessProgramValidation = (msg: IProgramValidation) => {
        processNotification(msg.id);
    };

    const onStopProcessProgramValidation = (msg: IProgramValidation) => {
        stopProcessNotification(msg.id);
    };

    /**
     * Disconnect when we leave the form
     */
    const disconnect = () => {
        unsubscribe("new-program-validation");
        unsubscribe("all-program-validation");
        unsubscribe("process-program-validation");
        unsubscribe("stop-process-program-validation");
        unsubscribe("all-program-validation-sp");
        unsubscribe("only-program-validation-sp");
        unsubscribe("only-program-validation");
        unsubscribe("only-program-validated");
        unsubscribe("remove-program-validation");
        unsubscribe("new-special-pricing-validation");
        unsubscribe("program-validated");
        // disconnectSocket(openedProgram && openedProgram.id);
    };

    const closeNotification = (id: string) => {
        setProgramsToValidateA((prev) => [
            ...prev.map((n) => ({ ...n, show: n.id === id ? false : n.show })),
        ]);
        setPriceError("");
    };

    const processNotification = (id: string) => {
        setProgramsToValidateA((prev) => [
            ...prev.map((n) => ({ ...n, status: n.id === id ? "in process" : n.status })),
        ]);
    };

    const stopProcessNotification = (id: string) => {
        setProgramsToValidateA((prev) => [
            ...prev.map((n) => ({ ...n, status: n.id === id ? "waiting" : n.status })),
        ]);
    };

    const deleteNotification = (id: string) => {
        setProgramsToValidateA((prev) => [...prev.filter((n) => n.id !== id)]);
    };

    const readNotification = (n: any, external?: boolean) => {
        processProgramValidation({ id: n.id });
        processNotification(n.id);
        if (!external) setOpenedProgram({ ...n });
        setAddProgram(false);
        methods.reset({ ...n.info.program.info, ...n.info.program });
        methods.clearErrors();
    };

    const restartProcessNotification = (id: string) => {
        stopProcessProgramValidation({ id: id });
        stopProcessNotification(id);
        setOpenedProgram(null);
    };

    const validateNotification = (id: string, info: any) => {
        validateProgramValidation({ ...info });
        deleteNotification(id);
    };

    const closeNotificationAgent = (id: string) => {
        setProgramsToValidateA((prev) => [
            ...prev.map((n) => ({ ...n, show: n.id === id ? false : n.show })),
        ]);
    };

    const reopenNotificationAgent = (id: string) => {
        setProgramsToValidateA((prev) => [
            ...prev.map((n) => ({ ...n, show: n.id === id ? true : n.show })),
        ]);
    };

    /**
     * Real Time communication
     */
    useEffect(() => {
        setTimeout(connect, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canAddProgram, canValidate, canValidateSpecialPricing]);

    const methods = useForm<CreateProgramPopup>({ mode: "onBlur", defaultValues: {} });
    const data = methods.watch();

    const onSubmitValidation = () => {
        if (Object.keys(methods.formState.errors).length === 0) {
            if (data.price || data.cost) {
                if (checkPrice(priceError, data, setPriceError, openedProgram)) return;
            }
            validateNotification(openedProgram.id, formatProgramValidation(openedProgram, data));
            setOpenedProgram(null);
        } else {
            onSubmitAddProgram();
        }
    };

    const handleOnSuccess = async () => {
        /**
         * Shows a success notification.
         */
        openNotification(NOTIFY_CLIENT_SAVED.OK);
        /**
         * Finish program on real time service
         */
        finishProgramValidation(openedProgram.id);
        // Close popup
        setOpenedProgram(null);
        /**
         * Delete notification from array
         */
        deleteNotification(openedProgram.id);

        window.location.reload();
    };

    const handleOnSuccessLead = async (res: any) => {
        /**
         * Shows a success notification.
         */
        openNotification(NOTIFY_CLIENT_SAVED.OK);
        /**
         * Finish program on real time service
         */
        finishProgramValidation(openedProgram.id);
        // Close popup
        setOpenedProgram(null);
        /**
         * Delete notification from array
         */
        deleteNotification(openedProgram.id);

        window.location.href = "/client/" + res.program.client_id + "/programs";
    };

    /**
     * A hook to handle errors.
     */
    const handleOnError = useHandleError();
    /**
     * The following hook is used for saving the leads form.
     * If saving is successfull, the handleOnSuccess function is called &
     * if an error occurs while saving the handleOnError funciton is called.
     */
    const [isUpdating, saveFormData] = useUpdate(
        API.POST_CLIENT_PROGRAM,
        handleOnSuccess,
        handleOnError
    );
    const [isUpdatingLead, saveFormDataLead] = useUpdate(
        API.POST_LEAD_PROGRAM,
        handleOnSuccessLead,
        handleOnError
    );
    const onSubmitAddProgram = () => {
        /**
         * This function is called when the form is submitted
         * We send the form data and add the person_id to know what client are we updating
         */
        methods.handleSubmit((data: any) => {
            if (checkPrice(priceError, data, setPriceError, openedProgram)) return;
            if (data.lead_id) {
                saveFormDataLead({
                    program: newProgram(
                        data.lead_id,
                        data.agent_id,
                        {
                            ...data,
                            info: {
                                ...data.info,
                                notes: data.notes,
                            },
                        },
                        true,
                        undefined, // lead
                        true // validation
                    ),
                    id: data.lead_id,
                    proposal_id: openedProgram.info.proposal_id,
                });
            } else {
                saveFormData({
                    program: newProgram(
                        data.client_id,
                        data.agent_id,
                        {
                            ...data,
                            info: {
                                ...data.info,
                                notes: data.notes,
                            },
                        },
                        true,
                        undefined, // lead
                        true // validation
                    ),
                    id: data.client_id,
                    proposal_id: openedProgram.info.proposal_id,
                });
            }
        })();
    };

    useEffect(() => {
        if (!openedProgram?.external) return;
        if (openedProgram.addProgram) {
            readValidatedNotification(openedProgram, true);
        }
        if (openedProgram.validate) {
            readNotification(openedProgram, true);
        }
    }, [openedProgram]);

    const readValidatedNotification = (n: any, external?: boolean) => {
        closeNotificationAgent(n.id);
        if (!external) setOpenedProgram({ ...n });
        setAddProgram(true);
        methods.reset({ ...n.info.program, ...n.info.program.info });
        methods.clearErrors();
    };

    const onCloseFunction = () => {
        setOpenedProgram(null);
        if (addProgram) reopenNotificationAgent(openedProgram.id);
        else restartProcessNotification(openedProgram.id);
    };

    /**
     * Return the notifications that will popup on the user's screen
     */
    return (
        <>
            <div
                id="container"
                className="fixed bottom-5 md:left-20 left-5 z-[99] flex flex-col-reverse gap-y-4"
            >
                {/* New program validation created */}
                {/* {programsToValidateA.map((n: any, i: number) => {
                    if (!n.show || n.status !== "waiting") return "";
                    return (
                        <Notification
                            key={n.id}
                            title={n.info.client_name || n.info.lead_name}
                            subtitle={`New Program Validation Needed`}
                            buttonText="Check it!"
                            onClose={() => closeNotification(n.id)}
                            onRead={() => readNotification(n)}
                            onValidate={() => validateNotification(n.id, {})}
                            specialPrice={n.info.program.special_pricing}
                        />
                    );
                })} */}
                {/* Notifications for program created after program validation */}
                {/* {programsToValidateA.map((n: any, i: number) => {
                    if (!n.show || n.status !== "validated") return "";
                    return (
                        <Notification
                            key={n.id}
                            title={n.info.client_name || n.info.lead_name}
                            subtitle="Program Validated"
                            buttonText="Close deal"
                            onClose={() => closeNotificationAgent(n.id)}
                            onRead={() => readValidatedNotification(n)}
                            onValidate={() => {}}
                            specialPrice={false}
                        />
                    );
                })} */}
            </div>
            {openedProgram !== null && (
                <>
                    <FormProvider {...methods}>
                        <ValidateProgramPopup
                            clickFunctionCancelButton={() => setOpenedProgram(null)}
                            onCloseFunction={onCloseFunction}
                            program={openedProgram.info.program.name}
                            onSubmit={onSubmitAddProgram}
                            isUpdating={isUpdating || isUpdatingLead}
                            validating
                            person_id={openedProgram.info.id}
                            addProgram={addProgram}
                            cancelFunction={() => setCancelProgram(true)}
                            setPriceError={setPriceError}
                            priceError={priceError}
                            lead={!!openedProgram.info.program.lead_id}
                        />
                    </FormProvider>
                </>
            )}
            {cancelProgram && (
                <CancelProgramPopup
                    clickFunctionCancelButton={() => setCancelProgram(false)}
                    handleOnSuccess={handleOnSuccess}
                    proposal_id={openedProgram.info.proposal_id}
                    person_id={
                        openedProgram.info.program.client_id || openedProgram.info.program.lead_id
                    }
                    price={data.price}
                    lead={!!openedProgram.info.program.lead_id}
                    program_id={openedProgram.info.program.id}
                    info={getInfoForProgram({ ...data } as any)}
                />
            )}
        </>
    );
}
