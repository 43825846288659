import { UseFormRegister } from "react-hook-form";
import { ActionMeta, MultiValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import { DropdownIndicator, ClearIndicator, MultiValueRemove } from "./CustomSelect";

export type ReynaSelectProps = {
    title?: string;
    options: any[] | null;
    row?: boolean;
    className?: string;
    selectClassName?: (val: boolean) => void;
    titleClassName?: string;
    multi?: boolean;
    placeholder?: string;
    value?: string | never | never[];
    onChangeFunction?: (newValue: MultiValue<never> | null, actionMeta: ActionMeta<never>) => void;
    editing?: boolean;
    name?: string;
    customContent?: boolean;
    searchable?: boolean;
    inputRegister?: UseFormRegister<any>;
    control?: any;
    textBlack?: boolean;
    menuPosition?: "fixed";
};

export const CretableCustomSelect = ({
    title,
    options,
    row,
    className,
    selectClassName,
    titleClassName,
    multi,
    placeholder,
    onChangeFunction,
    value,
    editing = false,
    name,
    menuPosition,
    customContent,
    searchable,
    inputRegister,
    control,
    textBlack = false
}: ReynaSelectProps) => {
    const handleinputs = ({ props, state, methods }: any) => {
        return props?.values?.length ? (
            <p>{`Selected ${props?.values?.length}`}</p>
        ) : (
            <p>{placeholder}</p>
        );
    };

    const adddtionalStuff = () => {
        if (customContent) {
            return { contentRenderer: handleinputs };
        }
        return {};
    };

    const selectStyles = () => {
        return {
            control: (state: any) => ({
                border: "1px solid var(--color-color3)",
                color: !editing && !textBlack ? "var(--color-color1)" : "var(--color-color7)",
                display: "flex",
                outline: "2px solid transparent",
                outlineOffset: "2px",
                borderRadius: "7px",
                backgroundColor: !editing ? "var(--color-color15)" : "var(--color-color8)",
                fontSize: "14px",
                ...control,
                height: multi ? "auto" : "34px",
                minHeight: "34px",
            }),
            dropdownIndicator: (provided: any) => ({
                ...provided,
                padding: "8px",
            }),
            indicatorSeparator: () => ({
                display: "none",
            }),
            valueContainer: (provided: any) => ({
                ...provided,
                padding: "0 8px",
                flexDirection: "row",
            }),
            singleValue: (provided: any) => ({
                ...provided,
                color: !editing && !textBlack ? "var(--color-color1)" : "var(--color-color7)",
            }),
            multiValue: (provided: any) => ({
                ...provided,
                background: 'var(--color-color3)',
            }),
            option: (provided: any, state: any) => ({
                ...provided,
                background: state.isSelected ? 'var(--color-color3)' : '',
                color: state.isSelected ? 'var(--color-color7)' : '',
                '&:hover': {
                    background: 'var(--color-color1)',
                    color: 'var(--color-color7-contrast)'
                },
            })
        };
    };

    return (
        <div
            className={`flex ${row ? "flex-row items-center" : "flex-col"} ${className ? className : "gap-2"
                }`}
            data-testid={`${name}-test`}
        >
            {title && (
                <span
                    className={`${titleClassName
                        ? titleClassName
                        : "font-roboto font-medium text-sm text-color7 whitespace-nowrap"
                        }`}
                >
                    {title}
                </span>
            )}
            <div className="w-full text-r-medium-small-2">
                <CreatableSelect
                    name={name}
                    styles={selectStyles()}
                    isDisabled={!editing}
                    className="react-select"
                    menuPosition={menuPosition || undefined}
                    classNames={{
                        multiValueRemove: () => "hover:!text-color11 text-color1 flex items-center"
                    }}
                    isClearable
                    maxMenuHeight={200}
                    options={options as never[]}
                    placeholder={placeholder ? placeholder : "Select Option"}
                    isMulti={multi}
                    isLoading={options === null}
                    value={
                        (!multi && ({ value: value, label: value } as never)) || (value as never[])
                    }
                    onChange={onChangeFunction ? onChangeFunction : () => { }}
                    {...adddtionalStuff()}
                    menuPlacement="auto"
                    components={{
                        DropdownIndicator,
                        ClearIndicator,
                        MultiValueRemove,
                    }}
                />
            </div>
        </div>
    );
};
