import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { API, API_BASEURL, buildUrl, ROUTES, SWR_CONFIG } from "../../config";
import useGetSWR from "../../hooks/useGetSWR";
import { UsersLogo } from "../../icons";
import { ActivityAll, iActivityRes } from "../../types";
import { timeFromNowFormatter } from "../../utils";
import FeedBox from "./FeedBox";
import FeedBoxItem from "./FeedBoxItem";
import { FormLoader } from "../loaders";

interface Iprops {
    id?: string;
    href?: string;
}

export const FeedBoxActivity = ({ id, href }: Iprops) => {
    // used for jumping between tabs
    const history = useHistory();

    // Array of notes
    const [activities, setActivities] = useState<ActivityAll[]>([]);

    // Sets to false after the first api call
    const [firstCall, setFirstCall] = useState(true);

    const url_notes = buildUrl(API.ALL_ACTIVITIES, {}, API_BASEURL, {
        contact: id,
        limit: 2,
        order: "DESC", // sort by latest first
    }); // build url to get latest notes

    const { data: activityRes } = useGetSWR<iActivityRes>(url_notes, SWR_CONFIG.FEEDBOX_NOTES);

    // Used for jumping to the notes tab
    const handleClick = () => {
        history.push(buildUrl(ROUTES.ACTIVITY, {}, "", { id }));
    };

    useEffect(() => {
        if (activityRes?.activity) {
            setActivities([...activityRes?.activity]);
            setFirstCall(false);
        }
    }, [activityRes]);

    // While fethcing data the first time show the loading indicator
    if (!id || firstCall) {
        return (
            <div className="flex justify-center items-center bg-color8 w-full lg:w-full rounded-[10px] h-48 p-5 gap-6">
                <FormLoader />
            </div>
        );
    }

    return (
        <FeedBox href={href} title="Timeline" buttonTitle="More activities" buttonFunction={handleClick}>
            {activities?.map((activity, index) => (
                <FeedBoxItem
                    key={index}
                    name={activity.description}
                    text1={activity.agent ? activity.agent : activity?.user?.name}
                    text2={timeFromNowFormatter(activity.created_at)}
                    Icon={UsersLogo}
                />
            ))}
        </FeedBox>
    );
};
