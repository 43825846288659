import Loader from "react-loader-spinner";

interface iProps {
    size?: number;
    color?: string;
    className?: string;
}

export const FormLoader = ({ size = 48, color = "var(--color-color7)", className }: iProps) => {
    return (
        <div className={`flex w-full h-full justify-center items-center ${className && className}`}>
            <Loader type="ThreeDots" color={color} height={size} width={size} />
        </div>
    );
};
