import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { API, API_BASEURL, buildUrl, SWR_CONFIG } from "../../config";
import useGetSWR from "../../hooks/useGetSWR";
import { UsersLogo } from "../../icons";
import { iNote, INotesRes } from "../../types";
import FeedBox from "./FeedBox";
import FeedBoxItem from "./FeedBoxItem";
import { FormLoader } from "../loaders";

interface Iprops {
    id?: string;
}

export const FeedBoxNotes = ({ id }: Iprops) => {
    // used for jumping between tabs
    const history = useHistory();

    // Array of notes
    const [notes, setNotes] = useState<iNote[]>([]);

    // Sets to false after the first api call
    const [firstCall, setFirstCall] = useState(true);

    const url_notes = buildUrl(API.ALL_NOTES, {}, API_BASEURL, {
        id,
        limit: 2,
    }); // build url to get latest notes

    const { data: notesRes } = useGetSWR<INotesRes>(url_notes, SWR_CONFIG.FEEDBOX_NOTES);

    // Used for jumping to the notes tab
    const handleClick = () => history.push("notes");

    useEffect(() => {
        if (notesRes?.note) {
            setNotes([...notesRes.note]);
            setFirstCall(false);
        }
    }, [notesRes]);

    // While fethcing data the first time show the loading indicator
    if (!id || firstCall) {
        return (
            <div className="flex justify-center items-center bg-color8 w-full lg:w-full rounded-[10px] h-48 p-5 gap-6">
                <FormLoader />
            </div>
        );
    }

    return (
        <FeedBox title="Notes" buttonTitle="More notes" buttonFunction={handleClick}>
            {notes?.map((note, index) => (
                <FeedBoxItem
                    key={index}
                    name={note?.user?.name}
                    text1={note?.text?.replace(/<[^>]+>/g, "\n")}
                    Icon={UsersLogo}
                />
            ))}
        </FeedBox>
    );
};
