import { Autocomplete } from "../../autocomplete/Autocomplete";
import { Module } from "./Module";
import Notifications from "./Notifications";
import { User } from "./User";
interface IProps {
    hide: boolean;
}
function Navbar({ hide }: IProps) {
    return (
        <div className="hidden md:flex justify-end bg-color8 py-2 gap-4 sticky top-[56px] md:top-0 z-40 px-4 shadow-md">
            <div className="flex w-full justify-left">{!hide && <Autocomplete />}</div>
            <div className="md:flex gap-4 w-full items-center justify-end reyna_container">
                {/* {!hide && <Module name="Real Estate" tooltip={"Coming soon..."} />} */}
                <Notifications />
                <User hide={hide} />
            </div>
        </div>
    );
}

export default Navbar;
