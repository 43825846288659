import { LeadFilters, TleadAll } from "../../types";
import { simpleTimeDateFormatter } from "../../utils";

export const toLeadTableFormat = (data: TleadAll[]) => {
    return data.map((e: TleadAll) => ({
        first_name: e?.first_name + " " + e?.last_name,
        source: e?.source,
        sales_status: e?.sales_status,
        name: e?.agent_name,
        phone: e?.phone,
        activity_date: simpleTimeDateFormatter(e?.activity_date) || "",
        appointment_date: simpleTimeDateFormatter(e?.appointment_date),
        created_at: simpleTimeDateFormatter(e?.created_at),
        id: e?.person_id,
        checked: false,
    }));
};

export const leadDataToIds = (data: any[]) =>
    data
        // .filter((e) => e?.checked)
        .map((m) => m?.id)
        .join("%");

export const LEAD_FILTERS: LeadFilters = {
    source: "",
    source_details: "",
    created_at_to: "",
    created_at_from: "",
    last_activity_to: "",
    last_activity_from: "",
    agent_id: "",
    red_flag: "",
    contacted: "",
    preferred_language: "",
    real_estate_agent: "",
    funding_agent: "",
    gender: "",
    program: "",
    goal: "",
    own_rent: "",
    sales_status: "",
    tags: "",
    state: "",
    loan_officer: "",
    // check: false,
};

/**
 * Extract the LeadFilters attributes from any object.
 * @param param0
 * @returns
 */
export const extractFilters = ({
    source = "",
    source_details = "",
    created_at_to = "",
    created_at_from = "",
    last_activity_to = "",
    last_activity_from = "",
    agent_id = "",
    red_flag = "",
    contacted = "",
    preferred_language = "",
    real_estate_agent = "",
    funding_agent = "",
    gender = "",
    program = "",
    own_rent = "",
    goal = "",
    sales_status = "",
    tags = "",
    state = "",
    loan_officer = "",
}): LeadFilters => ({
    source,
    source_details,
    created_at_to,
    created_at_from,
    last_activity_to,
    last_activity_from,
    agent_id,
    red_flag,
    contacted,
    preferred_language,
    real_estate_agent,
    funding_agent,
    loan_officer,
    gender,
    program,
    own_rent,
    goal,
    sales_status,
    tags,
    state,
});
