import { useAuth0 } from "@auth0/auth0-react";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import Loader from "react-loader-spinner";
import { useRecoilValue } from "recoil";
import image from "../../assets/svg/pdf_icon.png";
import { API, API_BASEURL, buildUrl, SWR_CONFIG } from "../../config";
import { userInfo } from "../../config/recoil/atoms";
import { useViewDocument } from "../../hooks";
import useGetSWR from "../../hooks/useGetSWR";
import { EyeIconFilled } from "../../icons";
import { IDocumentRes } from "../../types";
import {
    fetcherUploader,
    getDateTimeInDbFormat,
    getDocumentName,
    notificationToShow,
    NOTIFY_DOCUMENT_SAVED,
} from "../../utils";
import { getToken } from "../../utils/auth";

interface iSelectCreditReport {
    id: string;
    setValue: (key: "credit_report", value: string) => void;
    value: string | undefined;
    validating?: boolean;
    lead?: boolean;
}

export const SelectCreditReport = ({
    id,
    setValue,
    value,
    validating,
    lead = false,
}: iSelectCreditReport) => {
    const url = buildUrl(lead ? API.LEAD_DOCUMENT_ALL : API.CLIENT_DOCUMENT_ALL, {}, API_BASEURL, {
        id,
    }); // build url to fetch files
    const { data: documentRes, mutate } = useGetSWR<IDocumentRes>(url, SWR_CONFIG.FORMS); // Get Documents

    const [setViewUrl, triggerView, isViewLoading] = useViewDocument();

    const handleViewDoc = async (url: string) => {
        const viewUrl = buildUrl(
            lead ? API.LEAD_DOCUMENT_VIEW : API.CLIENT_DOCUMENT_VIEW,
            {},
            API_BASEURL,
            { key: url }
        );
        setViewUrl(viewUrl);
        triggerView();
    };

    const [saving, setSaving] = useState(false);
    const [files, setFiles] = useState<FileList | null | any>([]);
    const auth0 = useAuth0();
    const user = useRecoilValue(userInfo);

    const saveFiles = async () => {
        const formData = new FormData();
        if (files.length === 0) return; // early return if files are empty

        setSaving(true);

        files.forEach((e: File) => formData.append("filesent", e));
        formData.append("person_id", id);
        formData.append("created_date", getDateTimeInDbFormat());
        formData.append("agent_id", user.person_id);

        const putUrlUpload = buildUrl(
            lead ? API.LEAD_DOCUMENT_UPLOAD : API.CLIENT_DOCUMENT_UPLOAD,
            {},
            API_BASEURL
        );
        const [res, error] = await fetcherUploader(putUrlUpload, await getToken(auth0), formData);
        notificationToShow(error, NOTIFY_DOCUMENT_SAVED);
        if (res.success) {
            setValue("credit_report", res[lead ? "lead_document" : "client_document"][0].id);
        }
        setFiles([]);
        setSaving(false); // stop loading indicator for either case.
        mutate();
    };

    const updateFiles = (response: FileList | null) => {
        setFiles([]);
        let f = [...files];
        if (response && response.length > 0) {
            for (const key in response) {
                !isNaN(parseFloat(key)) && f.push(response[key]);
                console.log(key);
                console.log(response);
            }
        } else {
            f = [];
        }
        setFiles([...f]);
    };

    return (
        <div className="flex flex-col w-full gap-4 col-span-8">
            <p className="font-inter font-bold text-base">
                {validating ? "Credit Report" : "Select the Credit Report from the Documents List"}
            </p>
            {validating ? (
                <>
                    {documentRes?.[lead ? "lead_document" : "client_document"].map((document) => {
                        if (document.id !== value) return null;
                        return (
                            <div className="flex flex-row w-full justify-between">
                                <div className="flex flex-row gap-3 items-center">
                                    <img
                                        src={image}
                                        className="h-5 w-5"
                                        alt="something went wrong"
                                    />
                                    <p>
                                        {getDocumentName(document.name)}
                                    </p>
                                </div>
                                <div
                                    className="cursor-pointer"
                                    onClick={() => handleViewDoc(document.url)}
                                >
                                    {isViewLoading ? (
                                        <Loader
                                            type="ThreeDots"
                                            color="var(--color-color1)"
                                            height={24}
                                            width={24}
                                        />
                                    ) : (
                                        <EyeIconFilled className="fill-current" />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </>
            ) : (
                <>
                    {documentRes?.[lead ? "lead_document" : "client_document"].map((document) => (
                        <div className="flex items-center gap-2" key={document.id}>
                            <input
                                className="bg-color8"
                                type="checkbox"
                                id={document.id}
                                onChange={(e) =>
                                    setValue(
                                        "credit_report",
                                        e.target.value === value ? "" : e.target.value
                                    )
                                }
                                checked={value === document.id}
                                value={document.id}
                            />
                            <label
                                className="font-inter capitalize text-r-medium-small-2"
                                htmlFor={document.id}
                            >
                                {getDocumentName(document.name)}
                            </label>
                        </div>
                    ))}
                    <FileUpload
                        onChangeFunction={(value: FileList | null) => updateFiles(value)}
                        uploadFiles={saveFiles}
                        files={files}
                        saving={saving}
                    />
                </>
            )}
        </div>
    );
};

type FileUploadProps = {
    editing?: boolean;
    onChangeFunction?: (arg0: FileList | null) => void;
    uploadFiles: () => void;
    files: any[];
    saving: boolean;
    multipleFiles?: boolean;
};

const FileUpload = ({
    onChangeFunction,
    editing,
    uploadFiles,
    files,
    saving,
    multipleFiles = true,
}: FileUploadProps) => {
    const [fileName, setFileName] = useState("");
    const ref: MutableRefObject<any> = useRef();
    const reset = () => (ref.current.value = "");

    useEffect(() => {
        if (files.length === 0) {
            reset();
            setFileName("");
        } else if (files.length > 0 && !saving) {
            uploadFiles();
        }
    }, [files]);

    return (
        <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-row w-full">
                <label className="flex flex-col sm:flex-row w-full justify-center">
                    <input
                        ref={ref}
                        disabled={editing === false}
                        onChange={(e) => {
                            if (!e.target.files) return;
                            const fileNames = Array.from(e.target.files).map((file) => file.name);
                            setFileName(fileNames.join(", "));
                            onChangeFunction && onChangeFunction(e.target.files);
                        }}
                        type="file"
                        accept=".pdf,.txt,.doc,.docx,.csv,.xlsx,.jpg,.jpeg,.png,.mp3,.wav,.au"
                        multiple={multipleFiles}
                        className="hidden bg-color8 custom_file_upload"
                    />
                    {/* <input
                        readOnly
                        value={(!editing && null) || fileName}
                        className="mr-[-7px] min-h-[34px] rounded-tl-[7px] rounded-tr-[7px] sm:rounded-tr-0 sm:rounded-bl-[7px] border border-color3 w-full lg:max-w-[700px]"
                    /> */}
                    <span
                        className={`flex justify-center min-w-[250px] bg-color2 mr-4
                    font-inter font-medium text-r-medium-small-2 text-color7 px-[28px] py-[7px] rounded-br-[7px] 
                    rounded-bl-[7px] sm:rounded-[7px] border-none whitespace-nowrap cursor-pointer`}
                    >
                        {saving ? (
                            <Loader type="ThreeDots" color="var(--color-color1)" height={24} width={24} />
                        ) : (
                            "Upload Credit Report File"
                        )}
                    </span>
                </label>
            </div>
        </div>
    );
};
