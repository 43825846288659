import Loader from "react-loader-spinner";
interface LoaderProps {
    className?: string;
}
export default function LoaderWrapper({ className }: LoaderProps) {
    return (
        <div className={`w-screen h-screen flex items-center justify-center absolute top-0 left-0 bg-color1 ${className}`}>
            <Loader type="Grid" color="white" height={100} width={100} />
        </div>
    );
}
