/**
 * This component is made to be used with React-Form-Hooks
 */

import { RegisterOptions, UseFormRegister } from "react-hook-form";

type InputProps = {
    name: string;
    title?: string;
    placeholder?: string;
    className?: string;
    inputClassName?: string;
    titleClassname?: string;
    editing?: boolean;
    isDisabled?: boolean;
    inputRegister: UseFormRegister<any>;
    validation?: RegisterOptions;
    children?: JSX.Element | false | undefined;
    attributes?: any;
    spanClassname?: string;
};

export const CheckboxRFH = ({
    name,
    title,
    placeholder,
    className,
    inputClassName,
    titleClassname,
    editing = true,
    inputRegister,
    validation = {},
    children,
    attributes,
    spanClassname,
}: InputProps) => {
    return (
        <div className={`flex flex-col ${className ? className : ""}`}>
            <div className="w-full flex flex-row items-center">
                <input
                    {...attributes}
                    className={`outline-none text-color7 border-color3 text-r-medium-small-2 focus:ring-color1
                      focus:border-color1 transition-all rounded-[7px] 
                      ${inputClassName ? inputClassName : " bg-color8"} ${editing === false ? " !bg-color15 !text-color1" : ""
                        } `}
                    type="checkbox"
                    data-testid={`${name}-test`}
                    id={name}
                    placeholder={placeholder}
                    disabled={editing === false}
                    {...inputRegister(name, { ...validation })}
                />
                {title && (
                    <label htmlFor={name} className={`ml-2 text-r-medium-small-2 ${titleClassname ? titleClassname : ""}`}>
                        <span
                            className={`block overflow-y overflow-x-hidden break-words ${spanClassname}`}
                        >
                            {title}
                        </span>
                    </label>
                )}
                {/* You can pass a component to display stuff under the input. Its usually used for
                displaying error msges */}
                {children && children}
            </div>
        </div>
    );
};
