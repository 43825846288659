import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { UseFormRegister } from "react-hook-form";
import { API, API_BASEURL, PERMISSIONS, SWR_CONFIG, buildUrl } from "../../config";
import { usePermissions } from "../../hooks";
import useGetSWR from "../../hooks/useGetSWR";
import { DynamicListSingleRes } from "../../types";
import { fetcherPost } from "../../utils";
import { getToken } from "../../utils/auth";
import { CretableCustomSelect } from "../dropdowns/genericDropdowns/CretableCustomSelect";
import { CustomSelect } from "./genericDropdowns/CustomSelect";

export type ReynaSelectProps = {
    title?: string;
    values?: string | never[] | undefined;
    onChangeFunction?: (newValue: any) => void;
    editing?: boolean;
    selectFirst?: boolean;
    className?: string;
    outerClass?: string;
    control?: React.CSSProperties;
    name?: string;
    search?: string;
    multi?: boolean;
    titleClassName?: string;
    selectClassName?: () => void;
    row?: boolean;
    placeholder: string;
    filters?: boolean;
    inputRegister?: UseFormRegister<any>;
    children?: JSX.Element | false | undefined;
    textBlack?: boolean;
    readonly?: boolean;
    empty?: boolean;
    handleExclude?: () => void;
    valueExclude?: boolean;
    menuPosition?: "fixed";
};

export const DropdownDynamicList = ({
    title,
    onChangeFunction,
    selectFirst,
    values,
    editing,
    className,
    name,
    multi,
    search,
    titleClassName,
    selectClassName,
    row = false,
    placeholder,
    inputRegister,
    menuPosition,
    filters = false,
    children,
    outerClass,
    control,
    textBlack = false,
    readonly = false,
    empty = false,
    handleExclude,
    valueExclude,
}: ReynaSelectProps) => {
    const [options, setOptions] = useState<any[] | null>(null);
    const url = buildUrl(API.BY_NAME_SETTING, {}, API_BASEURL, { search });
    const { checkPermission } = usePermissions();
    const auth0 = useAuth0();
    const { data: dynamicListRes } = useGetSWR<DynamicListSingleRes>(url, SWR_CONFIG.FORMS);

    const sortList = (a: any, b: any) => {
        if (a.order !== b.order) {
            return a.order - b.order;
        }
        if (a.value.toLowerCase() < b.value.toLowerCase()) {
            return -1;
        }
        if (a.value.toLowerCase() > b.value.toLowerCase()) {
            return 1;
        }
        return 0;
    };

    useEffect(() => {
        if (dynamicListRes?.dynamic_list[0]?.dynamic_list_values) {
            selectFirst &&
                !values &&
                onChangeFunction?.(dynamicListRes?.dynamic_list[0]?.dynamic_list_values[0]?.value);
            const options: { label: string; value: string | null }[] =
                dynamicListRes?.dynamic_list[0]?.dynamic_list_values
                    ?.sort(sortList)
                    .map((l) => ({ label: l.value, value: l.value }))
            if (empty) {
                options?.push({ label: "Empty", value: "Empty" });
            }
            setOptions(options);
        }
    }, [dynamicListRes]);

    const handleChange = (value: any, action: any) => {
        if (action.action === "clear") {
            multi && onChangeFunction && onChangeFunction([]);
            !multi && onChangeFunction && onChangeFunction("");
        } else if (action.action === "remove-value") {
            multi && onChangeFunction && onChangeFunction(value);
        } else if (action.action === "select-option") {
            if (onChangeFunction) {
                multi ? onChangeFunction(value) : onChangeFunction(value.value);
            }
        } else if (!filters && checkPermission(PERMISSIONS.SETTINGS_UPDATE_DROPDOWNS)) {
            const newAction = { label: action.option.label, value: action.option.value };
            const newOptions = (options ? [...options, newAction] : [newAction]).sort(sortList);
            if (onChangeFunction) {
                multi ? onChangeFunction(value) : onChangeFunction(value.value);
            }
            setOptions(newOptions);
            handleSave(newAction.value);
        }
    };

    const handleSave = async (newValue: string) => {
        const uri = buildUrl(API.NEW_DYNAMIC_LIST_VALUE, {}, API_BASEURL);
        const dataToSave = { value: newValue, list_name: search };
        const [data, error] = await fetcherPost(uri, await getToken(auth0), { ...dataToSave });
        if (error || !data.success) {
            console.log(error);
        }
    };

    return (
        <div className={`w-full ${outerClass && outerClass}`}>
            {checkPermission(PERMISSIONS.SETTINGS_UPDATE_DROPDOWNS) && !filters ? (
                <CretableCustomSelect
                    title={title}
                    name={name}
                    editing={editing}
                    row={row}
                    titleClassName={` ${titleClassName
                        ? titleClassName
                        : "font-roboto text-base font-medium text-color7 w-full max-w-[90px] text-left w-full"
                        }`}
                    className={`${className
                        ? className
                        : "flex-col sm:flex-row sm:items-center sm:justify-center gap-[24px]"
                        }`}
                    selectClassName={selectClassName}
                    value={values}
                    menuPosition={menuPosition}
                    options={options}
                    onChangeFunction={handleChange}
                    placeholder={placeholder}
                    control={control}
                    multi={multi}
                    textBlack={textBlack}
                ></CretableCustomSelect>
            ) : (
                <CustomSelect
                    title={title}
                    name={name}
                    editing={editing}
                    row={row}
                    menuPosition={menuPosition}
                    titleClassName={` ${titleClassName
                        ? titleClassName
                        : "font-roboto text-base font-medium text-color7 w-full max-w-[90px] text-left w-full"
                        }`}
                    className={`${className
                        ? className
                        : "flex-col sm:flex-row sm:items-center sm:justify-center gap-[24px]"
                        }`}
                    value={values}
                    options={options}
                    onChangeFunction={handleChange}
                    placeholder={placeholder}
                    multi={multi}
                    control={control}
                    containerClassname=""
                    textBlack={textBlack}
                    readonly={readonly}
                />
            )}
            {valueExclude !== undefined && handleExclude && (
                <div className="pt-2">
                    <label className="flex items-center gap-2 font-inter text-color7 text-r-medium-small">
                        <input
                            className={`outline-none text-color7  ${!editing ? " bg-color15 text-color1" : " bg-color8"
                                }  !border-color3 !rounded-md focus:ring-color1 focus:border-color1 transition-all`}
                            type="checkbox"
                            onChange={() => {
                                handleExclude();
                            }}
                            disabled={false}
                            value={name + "_exclude"}
                            checked={valueExclude}
                        />
                        Exclude
                    </label>
                </div>
            )}
            {children && children}
        </div>
    );
};
