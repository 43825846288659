import { Fragment } from "react";
import { AccountNumber, CreateProgramPopup } from "../../types/ClientProgram";
import Input from "./Input";
import Button from "../buttons/Button";
import { AddCircle, DeleteIcon } from "../../icons";
import { Control, useFieldArray } from "react-hook-form";

interface AccountNumbersProps {
    title: string;
    isEditable: boolean;
    width: string;
    control: Control<CreateProgramPopup, any>;
}

export const AccountNumbers = ({ title, isEditable, width, control }: AccountNumbersProps) => {
    const { fields, append, remove, update } = useFieldArray<CreateProgramPopup>({
        control,
        name: "account_numbers", // unique name for your Field Array
        keyName: "rowId" as "id",
        rules: {
            required: true,
        },
    });
    const handleNewRow = () => {
        append({
            account_name: "",
            eq: false,
            tu: false,
            ex: false,
        });
    };
    const handleUpdate = (index: number, key: keyof AccountNumber, value: any) => {
        if (!fields?.length || !fields?.[index]) return;
        update(index, { ...fields?.[index], [key]: value });
    };
    const handleDelete = (index: number) => {
        remove(index);
    };
    return (
        <div key={title} className={`${width} flex sm:flex-col items-center sm:items-start`}>
            <div className="mb-2 flex w-full justify-between items-center">
                {title}
                {isEditable && (
                    <Button
                        Icon={AddCircle}
                        title="Add Account"
                        className={`!bg-color1 self-start text-r-medium font-medium p-2 px-2`}
                        iconClass={"text-color8 w-[18px] h-[18px]"}
                        clickFunction={handleNewRow}
                        titleClass={""}
                    />
                )}
            </div>
            <div className="flex-row grid gap-y-2 w-full">
                {fields?.map((account, index) => (
                    <div className="flex gap-x-4 items-center h-full w-full py-2" key={account.id}>
                        <Input
                            editing={isEditable}
                            title=""
                            type="text"
                            className={`w-full gap-2 justify-start my-4 md:my-0 md:justify-center lg:justify-start md:items-center`}
                            inputClassName="w-full"
                            value={account.account_name}
                            titleClassname=""
                            isChecked={account.eq}
                            onChangeFunction={(value) => handleUpdate(index, "account_name", value)}
                        />
                        <Input
                            editing={isEditable}
                            type="checkbox"
                            checkboxNotClickable={false}
                            title="EX"
                            className={`md:col-span-5 flex-row-reverse gap-2 justify-start my-4 md:my-0 md:justify-center lg:justify-start md:items-center`}
                            inputClassName={`p-3 custom_checkbox`}
                            value={"certified"}
                            titleClassname=""
                            isChecked={account.ex}
                            onChangeFunction={() => handleUpdate(index, "ex", !account.ex)}
                        />
                        <Input
                            editing={isEditable}
                            type="checkbox"
                            checkboxNotClickable={false}
                            title="TU"
                            className={` md:col-span-5 flex-row-reverse gap-2 justify-start my-4 md:my-0 md:justify-center lg:justify-start md:items-center`}
                            inputClassName={`p-3 custom_checkbox`}
                            value={"certified"}
                            titleClassname=""
                            isChecked={account.tu}
                            onChangeFunction={() => handleUpdate(index, "tu", !account.tu)}
                        />
                        <Input
                            editing={isEditable}
                            type="checkbox"
                            checkboxNotClickable={false}
                            title="EQ"
                            className={` md:col-span-5 flex-row-reverse gap-2 justify-start my-4 md:my-0 md:justify-center lg:justify-start md:items-center`}
                            inputClassName={`p-3 custom_checkbox`}
                            value={"certified"}
                            titleClassname=""
                            isChecked={account.eq}
                            onChangeFunction={() => handleUpdate(index, "eq", !account.eq)}
                        />
                        {isEditable && (
                            <DeleteIcon
                                className="text-color7 fill-current hover:text-color2 cursor-pointer h-6 w-6"
                                onClick={() => handleDelete(index)}
                            />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};
