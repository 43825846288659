import { API, API_BASEURL, buildUrl, SWR_CONFIG } from "../../config";
import useGetSWR from "../../hooks/useGetSWR";
import { IlanguageRes } from "../../types";
import { CustomSelect } from "./genericDropdowns/CustomSelect";

type InputProps = {
    title?: string;
    placeholder?: string;
    name?: string;
    onChangeFunction?: (arg0: any) => void;
    className?: string;
    inputClassName?: string;
    titleClassname?: string;
    editing?: boolean;
    textBoxClass?: string;
    value?: any;
    isMulti?: boolean;
    empty?: boolean;
};

export const DropDownLanguages = ({
    title,
    placeholder = "",
    name = "",
    onChangeFunction,
    className,
    inputClassName,
    titleClassname,
    editing,
    textBoxClass,
    value = "",
    isMulti = false,
    empty = false,
}: InputProps) => {
    const url = buildUrl(API.ALL_LANGUAGES, {}, API_BASEURL);

    const { data: languageListRes } = useGetSWR<IlanguageRes>(url, SWR_CONFIG.FORMS);
    const onChange = (val: any, action: any) => {
        if (!onChangeFunction) return "";
        if (action.action === "clear") onChangeFunction(isMulti ? [] : "");
        else onChangeFunction(isMulti ? val : val.value);
    };

    const toLabelValue = () => {
        const options = [];
        if (!languageListRes) return [];
        for (const lang of languageListRes?.language) {
            options.push({ value: lang.id, label: lang.name });
        }
        if (empty) options.push({ value: "Empty", label: "Empty" });
        return options;
    };

    const getValue = () => {
        if (!languageListRes) return "";
        if (value === "Empty") return { label: "Empty", value: "Empty" };
        const lang = languageListRes?.language.find((l) => l.id === value);
        if (!lang) return "";
        return { label: lang.name, value: value };
    };

    const getValueMulti = () => {
        const values = [];
        for (const v of value) {
            if (v.value === "Empty") values.push({ value: "Empty", label: "Empty" });
            else
                values.push({
                    value: (v as any).value,
                    label: languageListRes?.language.find((l) => l.id === (v as any).value)?.name,
                });
        }
        return values;
    };

    return (
        <CustomSelect
            title={title}
            placeholder={placeholder}
            name={name}
            onChangeFunction={onChange}
            className={className ? className : "flex flex-col mb-4"}
            titleClassName={titleClassname ? titleClassname : "text-r-medium-small-2"}
            editing={editing}
            containerClassname="capitalize"
            isObject
            multi={isMulti}
            value={isMulti ? getValueMulti() : getValue()}
            options={toLabelValue()}
            inputClassname={inputClassName}
            control={{ height: "undefined" }}
        />
    );
};
