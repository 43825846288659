import React from "react";
import ReactDOM from "react-dom";
import 'react-tooltip/dist/react-tooltip.css';
import "@algolia/autocomplete-theme-classic/dist/theme.css";
import 'react-datepicker/dist/react-datepicker.css';
import "./index.css";
import App from "./App";
// import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import { RecoilRoot } from "recoil";
ReactDOM.render(
    <React.StrictMode>
        <RecoilRoot>
            <Auth0Provider
                domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
                redirectUri={process.env.REACT_APP_URL || "https://crm-lareyna.vercel.app/"}
                issuer={process.env.REACT_APP_AUTH0_DOMAIN}
                clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
                audience="https://crm-la-reyna.com/database"
                useRefreshTokens={true}
            >
                <App />
            </Auth0Provider>
        </RecoilRoot>
    </React.StrictMode>,
    document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
