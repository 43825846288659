import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

/**
 *
 * @returns The current time with time zone.
 */
export const getDateTimeInDbFormat = () => new Date().toString();

/**
 * This function is used to format the dates for notes
 * @param date timestamp or ISOS date format
 * @returns date that is formatted like this > 'Jan 4 2021'
 */
export const dateFormatter = (date: any) => {
    if (dayjs(date).isValid()) {
        return dayjs(date).format("MM/DD/YY");
    } else {
        return "";
    }
};


/**
 *  Used to display how long something has happen from now.
 *  Eg. posted 10 mins ago or one year ago.
 * @param date timestamp or ISOS date format
 * @returns time from now eg. 20 years ago, 20 seconds ago
 */
export const timeFromNowFormatter = (date: any) => {
    dayjs.extend(relativeTime);
    return dayjs(date).fromNow();
};

/**
 * This function is used to format the dates for notes
 * @param date timestamp or ISOS date format
 * @returns date that is formatted like this > '10:42 PM'
 */
export const timeFormatter = (date: any) => {
    return dayjs(date).format("h:mm A");
};

/**
 * This function is used to display date for file uploads.
 * @param date in ISOS date string format
 * @returns date that is formatted like this > '2021-04-12'
 */
export const fileDateFormatter = (date: string) => {
    return dayjs(date).format("YYYY-MM-D"); // '2021-04-12'
};

/**
 * This function is used for file report to format the date.
 * @param date in ISOS date string format
 * @returns date that is formatted like this > '7_10_2021_16_42_32'
 */
export const reportDateFormatter = () => {
    return dayjs().format("D_MM_YYYY_HH_mm_ss"); // '7_10_2021_16_42_32'
};

export const activityTimeDateFormatter = (date: Date) => {
    return tableTimeDateFormatter(date);
};

export const activityDateFormatter = (date: string) => {
    return toHtmlDateTableFormat(date);
};

/**
 * This function takes a timestamp or ISO date time format and return is formatted
 *  like this > 9:41 AM 09/29/2021. If no value is passed or if the value is undefined
 *  it will return the current date and time.
 * @param date date in timestamp or ISO time format
 * @returns time & date formatted like > 9:41 AM 09/29/2021
 */
export const tableTimeDateFormatter = (date: string | Date | undefined) => {
    // if (date) return dayjs(date).format("h:mm A MM/D/YYYY");
    // else return dayjs().format("h:mm A MM/D/YYYY");
    if (date) return dayjs(date).format("MM/D/YYYY h:mm A");
    else return dayjs().format("MM/D/YYYY h:mm A");
};

export const simpleTimeDateFormatter = (date: string | Date | undefined) => {
    const dateOut = dayjs(date).format("MM/D/YYYY h:mm A");
    return dateOut === "Invalid Date" ? null : dateOut;
};

/**
 * Takes a timestamp and converts it in a format understandable by
 * the HTML input date type format.
 * @param date string
 * @returns a date format in html input date tag format
 */
export const tsToHtmlDateFormat = (date: string | undefined | null) => {
    if (date) return dayjs(date).format("YYYY-MM-DD");
    else return undefined;
};

/**
 * Takes a timestamp and converts it in a format understandable by
 * the HTML input date type format.
 * @param date string
 * @returns a date format in html input date tag format
 */
export const toHtmlDateTableFormat = (date: string | undefined | null) => {
    if (date) return dayjs(date).format("MM/DD/YYYY");
    else return undefined;
};
export const toHtmlDateTimeTableFormat = (date: string | undefined | null) => {
    if (date) return dayjs(date).format("MM/DD/YYYY HH:mm A");
    else return undefined;
}
export const toHtmlDateTimeTimzone = (date: string | undefined | null) => {
    if (date) return dayjs(date).format("YYYY-MM-DDTHH:mmZZ");
    else return undefined;
}

export const realTimeUsersFormatter = (users: any[], object_name: string) => {
    const printUsersName = (num: number, and: boolean) => {
        let out = users[0].name;
        for (let i = 1; i < num && i < users.length - 1; i += 1) {
            out += ", " + users[i].name;
        }
        if (and) out += " and " + users[users.length - 1].name;
        return out;
    };

    return users.length === 1
        ? `${printUsersName(1, false)} is watching this ${object_name}`
        : users.length > 1 && users.length < 4
        ? `${printUsersName(4, true)} are watching this ${object_name}`
        : users.length >= 4
        ? `${printUsersName(2, false)} and ${
              users.length - 2
          } more are watching this ${object_name}`
        : ``;
};

/**
 * Get document name
 * @param {string} phrase
 * @returns {string}
 */
export const getDocumentName = (name: string): string =>
    name?.includes("_") ? name.substring(name.indexOf("_") + 1) : name;

/**
 * Convert snakecase to titlecase
 * example: spouse_name -> Spouse Name
 * @param {string} phrase
 * @returns {string}
 */
export const toTitleCase = (phrase: string): string =>
    phrase
        .toLowerCase()
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

/**
 * Format number without decimals to dollar amount format
 * if value is null return is ""
 * Example: 5500 -> $5,500.00
 * @param {string} value value to format
 * @returns {string} value formatted
 */
export const amountUSDFormatter = (value: string): string => {
    if (value === null || value === undefined) {
        return "";
    }
    if (value === "******") return value;
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });
    const floatValue = parseFloat(value.toString().replace(/["$",","]/gi, ""));
    return formatter.format(floatValue);
};
