import { ActionMeta, components, MultiValue } from "react-select";
import Select from "react-select";
import { ArrowDownIcon, CloseIcon } from "../../../icons";
import { backdropClasses } from "@mui/material";

export type ReynaSelectProps = {
    title?: string;
    options: any[] | null;
    row?: boolean;
    className?: string;
    selectStyles?: any;
    titleClassName?: string;
    multi?: boolean;
    placeholder?: string;
    value?: any;
    onChangeFunction?: (newValue: MultiValue<never> | null, actionMeta: ActionMeta<never>) => void;
    editing?: boolean;
    name?: string;
    customContent?: boolean;
    searchable?: boolean;
    isClearable?: boolean;
    isObject?: boolean;
    children?: JSX.Element | false | undefined;
    inputClassname?: string;
    containerClassname: string;
    control?: any;
    textBlack?: boolean;
    readonly?: boolean;
    menuPosition?: "fixed";
    onSearch?: (val: string, e?: any) => void;
    loading?: boolean;
    menuPlacement?: 'auto' | 'bottom' | 'top';
};

export const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props} className="!pl-1 cursor-pointer !text-gray-500">
            <ArrowDownIcon className="fill-current size-[12px]" />
        </components.DropdownIndicator>
    );
};

export const ClearIndicator = (props: any) => {
    return (
        <components.ClearIndicator {...props} className="!px-1 !py-0 cursor-pointer hover:!text-color11 !text-gray-500 ">
            <CloseIcon className="fill-current size-[20px]" />
        </components.ClearIndicator>
    );
};

export const MultiValueRemove = (props: any) => {
    return (
        <components.MultiValueRemove {...props}>
            <CloseIcon className="fill-current size-[15px]" />
        </components.MultiValueRemove>
    );
};



export const CustomSelect = ({
    title,
    options,
    row,
    className,
    selectStyles,
    titleClassName,
    multi,
    placeholder,
    onChangeFunction,
    value,
    isObject = false,
    editing = false,
    name,
    customContent,
    searchable,
    menuPosition,
    isClearable = true,
    children,
    containerClassname,
    inputClassname,
    control,
    textBlack = false,
    readonly = false,
    onSearch,
    loading,
    menuPlacement,
}: ReynaSelectProps) => {
    const handleinputs = ({ props, state, methods }: any) => {
        return props?.values?.length ? (
            <p>{`Selected ${props?.values?.length}`}</p>
        ) : (
            <p>{placeholder}</p>
        );
    };

    const adddtionalStuff = () => {
        if (customContent) {
            return { contentRenderer: handleinputs };
        }
        return {};
    };

    const defaultSelectStyles = () => {
        return {
            control: (state: any) => ({
                border: "1px solid var(--color-color3)",
                color: !editing && !textBlack ? "var(--color-color1)" : "var(--color-color7)",
                display: "flex",
                outline: "2px solid transparent",
                outlineOffset: "2px",
                borderRadius: "7px",
                backgroundColor: !editing ? "var(--color-color15)" : "var(--color-color8)",
                fontSize: "14px",
                textAlign: "initial",
                ...control,
                height: multi ? "auto" : "34px",
                minHeight: "34px",
            }),
            dropdownIndicator: (provided: any) => ({
                ...provided,
                padding: "8px",
                visibility: readonly && "hidden",
            }),
            multiValueRemove: (provided: any) => ({
                ...provided,
                display: readonly && "none",
            }),
            indicatorSeparator: () => ({
                display: "none",
            }),
            valueContainer: (provided: any) => ({
                ...provided,
                padding: "0 8px",
            }),
            singleValue: (provided: any) => ({
                ...provided,
                color: !editing && !textBlack ? "var(--color-color1)" : "var(--color-color7)",
                "padding-right": readonly && "6px",
            }),
            multiValue: (provided: any) => ({
                ...provided,
                "padding-right": readonly && "6px",
                background: 'var(--color-color3)',
            }),
            indicatorsContainer: (provided: any) => ({
                ...provided,
                width: readonly && "0px",
            }),
            option: (provided: any, state: any) => ({
                ...provided,
                background: state.isSelected ? 'var(--color-color3)' : '',
                color: state.isSelected ? 'var(--color-color7)' : '',
                '&:hover': {
                    background: 'var(--color-color1)',
                    color: 'var(--color-color7-contrast)'
                },
            })
        };
    };
    return (
        <div
            className={`flex !text-color7 ${row ? "flex-row items-center" : "flex-col"} ${className ? className : "gap-2"
                }`}
            data-testid={`${name}-test`}
        >
            {title && (
                <span
                    className={`${titleClassName
                        ? titleClassName
                        : "font-roboto font-medium text-sm text-color7 whitespace-nowrap"
                        }`}
                >
                    {title}
                </span>
            )}
            <div className={`w-full text-r-medium-small-2 ${containerClassname}`}>
                <Select
                    name={name}
                    styles={defaultSelectStyles() as any}
                    isDisabled={!editing || readonly}
                    className={`${inputClassname ? inputClassname : "react-select"}`}
                    classNames={{
                        multiValueRemove: () => "hover:!text-color11 text-color7 flex items-center"
                    }}
                    isSearchable={searchable}
                    onInputChange={onSearch}
                    menuPosition={menuPosition || undefined}
                    isClearable={isClearable}
                    maxMenuHeight={200}
                    options={options as never[]}
                    isLoading={loading || options === null}
                    value={
                        value === "" || !value
                            ? null
                            : (!multi &&
                                ({
                                    value: isObject ? value.value : value,
                                    label: isObject ? value.label : value,
                                } as never)) ||
                            (value as never[])
                    }
                    placeholder={placeholder ? placeholder : "Select Option"}
                    isMulti={multi}
                    onChange={onChangeFunction ? onChangeFunction : () => { }}
                    {...adddtionalStuff()}
                    menuPlacement={menuPlacement || "auto"}
                    components={{
                        DropdownIndicator,
                        ClearIndicator,
                        MultiValueRemove,
                    }}
                />
                {children}
            </div>
        </div>
    );
};
