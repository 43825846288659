import { useHistory } from "react-router";
import { buildUrl, ROUTES } from "../../config";
import { FlagIcon, LanguageIcon, MailIcon, PersonCircle, PhoneIcon, PinIcon } from "../../icons";
import { Flag } from "../flag";
import { FormLoader } from "../loaders";
import { PreferredLanaguge } from "./Preferred_language";

export type PersonInfoProps = {
    first_name?: string;
    last_name?: string;
    name?: string;
    email?: string;
    date?: string;
    goal?: string;
    phone?: string;
    profile_image?: string;
    address?: string;
    preferred_language?: string;
    flag?: string;
    flagCheck?: boolean;
    editing?: boolean;
    buttonText?: string;
    url_lead?: string;
    agent_email?: string;
    buttonClickFunction?: () => void;
    flagClickFunction?: (val: boolean) => void;
    isLoading?: boolean;
    person_id: string;
    agent_name?: string;
    flagFor: "client" | "lead";
    agentId?: string;
    autocomplete?: boolean;
};

function PersonInfo({
    first_name,
    last_name,
    url_lead,
    profile_image,
    agent_name,
    goal,
    agent_email,
    date,
    email,
    phone,
    address,
    preferred_language,
    flag,
    flagCheck,
    buttonText = "",
    editing,
    buttonClickFunction,
    flagClickFunction,
    isLoading,
    person_id,
    flagFor,
    agentId,
    autocomplete = false,
}: PersonInfoProps) {
    const history = useHistory();

    const handleAgentClick = () => {
        // If comming from the autocomplete view on algolia, don't use history.push since it's outside the context
        if (autocomplete) return "";
        // if no agent id, pass any word. It will navigate to the single users page.
        // There is a function in the single users page that will handle missing agents.
        // So if a agent has been deleted, the single users page will let the user
        // know that the agent no longer exists.
        const id = agentId ? agentId : "empty";
        history.push(buildUrl(ROUTES.SINGLE_USERS, { id }));
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center bg-color8 w-full lg:w-full rounded-[10px] h-72 p-5 gap-6">
                <FormLoader />
            </div>
        );
    }
    return (
        <div className="flex flex-col bg-color8 w-full lg:w-full rounded-[10px] h-min p-5 gap-6">
            <div className="flex flex-col">
                <div>
                    <h1 className="text-r-large-big">{`${first_name || ""} ${last_name || ""}`}</h1>
                </div>
                <div>
                    <span className="font-inter font-medium text-r-medium-small text-color6">
                        {date}
                    </span>
                </div>
            </div>
            <div className="font-roboto font-regular text-r-medium-small-2 text-color7 text-opacity-50 gap-5 flex flex-col">
                {email && (
                    <div className="flex gap-2">
                        <MailIcon className="fill-current w-[20px]" />
                        <a href={`mailto:${email}`}>{email}</a>
                    </div>
                )}
                {phone && (
                    <div className="flex gap-2">
                        <PhoneIcon className="fill-current w-[20px]" />
                        <a href={`tel:${phone}`}>{phone}</a>
                    </div>
                )}
                {address && (
                    <div className="flex gap-2">
                        <PinIcon className="fill-current w-[20px]" />
                        <span>{address}</span>
                    </div>
                )}
                {preferred_language && (
                    <div className="flex gap-2">
                        <LanguageIcon className="fill-current w-[20px]" />
                        <PreferredLanaguge languageId={preferred_language} />
                    </div>
                )}

                <div className="flex flex-col items-start gap-5 justify-between">
                    <div className="flex items-center gap-2">
                        <FlagIcon className="fill-current w-[20px]" />
                        <span>{goal ? goal : "-"}</span>
                    </div>
                    <div
                        onClick={() => buttonClickFunction && buttonClickFunction()}
                        className="bg-color1 font-roboto text-r-medium py-[1px] font-medium text-color8 px-2 rounded-[5px] flex items-center gap-2 self-start"
                    >
                        <Flag
                            flagFor={flagFor}
                            person_id={person_id}
                            name={first_name + " " + last_name}
                            agent_email={agent_email}
                            agent_name={agent_name}
                            url_lead={url_lead}
                            autocomplete={autocomplete}
                        />
                        <div className={`flex gap-2 ${!autocomplete && 'cursor-pointer'}`} onClick={handleAgentClick}>
                            {profile_image ? (
                                <img
                                    src={profile_image}
                                    alt="agents"
                                    className="fill-current rounded-full w-[20px] h-[20px]"
                                />
                            ) : (
                                <PersonCircle className="fill-current h-[20px]" />
                            )}
                            <span>{buttonText}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default PersonInfo;
