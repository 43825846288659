import dayjs from "dayjs";
import {
    ADDRESS_REMOVAL,
    BANKRUPTCY,
    BUSINESS_VIP,
    CLUB_FICO_GUARANTEED,
    CREDIT_REPORT,
    FUNDING,
    HOMESTEAD,
    ID_THEFT,
    ID_THEFT_ALERTS,
    INQUIRIES,
    JUDGEMENT,
    LIVING_TRUST,
    MAILING_ADDRESS,
    MANUAL_PROGRAM,
    NEW_CREDIT_CARD,
    PUBLIC_RECORDS,
    RAPID_RESCORE,
    REGULAR,
    REMOVE_DISPUTES,
    RFV,
    SCORE_SIMULATOR,
    SINGLE_NEGOTIATION,
    TRADELINES,
    TRADELINES_FUNDING,
    VIP_CREDIT,
    ZERO_TO_HERO,
} from "./fields";

export const getFieldsByProgram = (program: string) => {
    switch (program) {
        case "Credit Report":
            return CREDIT_REPORT;
        case "Tradelines":
            return TRADELINES;
        case "Single Negotiation":
            return SINGLE_NEGOTIATION;
        case "Address Removal":
            return ADDRESS_REMOVAL;
        case "RFV":
            return RFV;
        case "Bankruptcy":
            return BANKRUPTCY;
        case "Judgement":
            return JUDGEMENT;
        case "Club Fico Guaranteed":
        case "CLUB 620":
        case "CLUB 620 CV":
        case "CLUB 640":
        case "CLUB 660":
        case "CLUB 680":
        case "CLUB 700":
        case "CLUB 720":
        case "CLUB 740":
        case "CLUB 760":
        case "CLUB 780":
        case "CLUB 800":
        case "CLUB 620 2nd Contract":
        case "CLUB 620 CV 2nd Contract":
        case "CLUB 640 2nd Contract":
        case "CLUB 660 2nd Contract":
        case "CLUB 680 2nd Contract":
        case "CLUB 700 2nd Contract":
        case "CLUB 720 2nd Contract":
        case "CLUB 740 2nd Contract":
        case "CLUB 760 2nd Contract":
        case "CLUB 780 2nd Contract":
        case "CLUB 800 2nd Contract":
        case "CLUB 620 VIP":
        case "CLUB 640 VIP":
        case "CLUB 660 VIP":
        case "CLUB 680 VIP":
            return CLUB_FICO_GUARANTEED;
        case "Credito y Casas":
            return MANUAL_PROGRAM;
        case "Inquiries":
            return INQUIRIES;
        case "Rapid Rescore":
            return RAPID_RESCORE;
        case "ID Theft Alert":
            return ID_THEFT_ALERTS;
        case "ID Theft":
            return ID_THEFT;
        case "Public Records":
            return PUBLIC_RECORDS;
        case "Mailing Address":
            return MAILING_ADDRESS;
        case "Score Simulator":
            return SCORE_SIMULATOR;
        case "Regular":
            return REGULAR;
        case "New Credit Card":
            return NEW_CREDIT_CARD;
        case "Remove Disputes":
            return REMOVE_DISPUTES;
        case "Homestead":
            return HOMESTEAD;
        case "Living Trust":
            return LIVING_TRUST;
        case "VIP Credit":
            return VIP_CREDIT;
        case "Business VIP":
            return BUSINESS_VIP;
        case "Funding":
        case "Funding - Business":
        case "Funding - Equity":
        case "Funding - Personal":
            return FUNDING;
        case "Tradelines Funding":
            return TRADELINES_FUNDING;
        case "Zero to Hero":
            return ZERO_TO_HERO;
        default:
            return MANUAL_PROGRAM;
    }
};

const POSSIBLE_PROGRAMS = [
    "Club Fico Guaranteed",
    "CLUB 620",
    "CLUB 620 CV",
    "CLUB 640",
    "CLUB 660",
    "CLUB 680",
    "CLUB 700",
    "CLUB 720",
    "CLUB 740",
    "CLUB 760",
    "CLUB 780",
    "CLUB 800",
    "CLUB 620 2nd Contract",
    "CLUB 620 CV 2nd Contract",
    "CLUB 640 2nd Contract",
    "CLUB 660 2nd Contract",
    "CLUB 680 2nd Contract",
    "CLUB 700 2nd Contract",
    "CLUB 720 2nd Contract",
    "CLUB 740 2nd Contract",
    "CLUB 760 2nd Contract",
    "CLUB 780 2nd Contract",
    "CLUB 800 2nd Contract",
    "CLUB 620 VIP",
    "CLUB 640 VIP",
    "CLUB 660 VIP",
    "CLUB 680 VIP",
];

export const isClubFico = (program: string) => {
    return POSSIBLE_PROGRAMS.includes(program);
};

export const needsValidation = (program: string, special_pricing?: boolean, club_type?: string) => {
    const programsWithValidation = [
        "Tradelines",
        "Single Negotiation",
        "ID Theft",
        "Club Fico Guaranteed",
    ];
    const programsWithoutValidation = [
        "Credit Report",
        "Address Removal",
        "RFV",
        "Bankruptcy",
        "Judgement",
        "Credito y Casas",
        "Inquiries",
        "Rapid Rescore",
        "ID Theft Alert",
        "Public Records",
        "Mailing Address",
        "Score Simulator",
        "Regular",
        "New Credit Card",
        "Remove Disputes",
        "Homestead",
        "Living Trust",
        "VIP Credit",
    ];

    if (special_pricing) return true;

    if (programsWithValidation.includes(program)) return true;
    else if (programsWithoutValidation.includes(program)) return false;
};

interface IAdditionalInfo {
    accounts_positive?: number;
    accounts_negative?: number;
    tradelines?: number;
    bk_type: string;
    cost?: string;
    price?: string;
    inquiries?: number;
    simulations?: number;
    records?: number;
    ex?: boolean;
    eq?: boolean;
    tu?: boolean;
    state?: string;
    creditor_1?: string;
    creditor_2?: string;
    creditor_3?: string;
    collection_amount_1?: number;
    collection_amount_2?: number;
    collection_amount_3?: number;
    account_1?: string;
    account_2?: string;
    account_3?: string;
    club?: string;
    club_type?: string;
    payment_option?: number;
    amount_approved?: string;
    property_value?: number;
    type?: string;
}

const getSingleNegotiationCollectionCost = (amount: number) => {
    if (amount <= 1000) return 150;
    else if (amount <= 2000) return 250;
    else if (amount <= 3000) return 350;
    else return 450;
};

interface ProgramPrice {
    cost: number;
    price: number | null;
}

export const calculateProgramPrice = (
    program: string,
    cr_pricing: number,
    payment_option?: number,
    additional_info?: IAdditionalInfo
) => {
    // For Credito y Casas program will be
    if (program === "Credito y Casas")
        return { cost: additional_info?.cost, price: additional_info?.price };
    if (program.includes("Funding - ") || program === 'Funding') return { cost: null, price: null };

    let { cost, price } = getProgramPrice(program, cr_pricing, additional_info);
    if (program === "Bankruptcy" ||
        program === "New Credit Card" ||
        program === "VIP Credit") {
        // For bankruptcy, new crdit card and vip credit there is no 2500 limit
        cost = cost + cr_pricing;
    } else if (program !== 'Tradelines Funding') {
        // For any other program but tradelines funding, limit of 2500 mas credit report cost
        if (cost > 2500) {
            cost = 2500 + cr_pricing;
        } else {
            cost = cost + cr_pricing;
        }
    }

    return { cost: cost, price: price };
};

const getProgramPrice = (
    program: string,
    cr_pricing: number,
    additional_info?: IAdditionalInfo
): ProgramPrice => {
    let cost = 0;
    switch (program) {
        case "Credit Report":
            return { cost: 0, price: cr_pricing };
        case "Tradelines":
            cost = 600 * (additional_info?.tradelines || 0);
            return { cost, price: null };
        case "Single Negotiation":
            if (
                additional_info?.creditor_1 &&
                additional_info.collection_amount_1 &&
                additional_info.account_1
            )
                cost += getSingleNegotiationCollectionCost(additional_info.collection_amount_1);
            if (
                additional_info?.creditor_2 &&
                additional_info.collection_amount_2 &&
                additional_info.account_2
            )
                cost += getSingleNegotiationCollectionCost(additional_info.collection_amount_2);
            if (
                additional_info?.creditor_3 &&
                additional_info.collection_amount_3 &&
                additional_info.account_3
            )
                cost += getSingleNegotiationCollectionCost(additional_info.collection_amount_3);
            return { cost, price: null };
        case "Address Removal":
            return { cost: 250, price: null };
        case "RFV":
            return { cost: 250, price: null };
        case "Bankruptcy":
            cost = additional_info?.bk_type === "SINGLE BK" ? 2338 : 3038;
            return { cost, price: null };
        case "Judgement":
            return { cost: 450, price: null };
        case "Club Fico Guaranteed":
            if (additional_info?.club_type === "Regular") {
                const n = additional_info?.accounts_negative || 0;
                const p = additional_info?.accounts_positive || 0;
                const state = additional_info?.state || "";
                cost = 0;
                if (n <= 4) {
                    cost = 600;
                    if (2 - p > 0) cost += (2 - p) * 600;
                } else if (n <= 8) {
                    cost = 1200;
                    if (2 - p > 0) cost += (2 - p) * 600;
                    if (p == 0 && cost === 2400) cost = 2500;
                } else if (n <= 12 || (state !== "CA" && n >= 13)) {
                    cost = 1800;
                    if (3 - p > 0) cost += (3 - p) * 600;
                    if (p == 2 && cost === 2400) cost = 2500;
                } else if (state === "CA" && n >= 13) {
                    cost = 2500;
                    if (3 - p > 0) cost += (3 - p) * 600;
                }
                if (state !== "CA" || additional_info?.club?.includes("CV")) {
                    cost = cost > 1800 ? 1800 : cost;
                }
                return { cost, price: null };
            } else if (additional_info?.club_type === "2nd Contract") {
                return { cost: 600, price: null };
            } else if (additional_info?.club_type === "VIP") {
                if (additional_info?.club === "CLUB 620") return { cost: 600, price: null };
                else if (additional_info?.club === "CLUB 640") return { cost: 1200, price: null };
                else if (additional_info?.club === "CLUB 660") return { cost: 1800, price: null };
                else if (additional_info?.club === "CLUB 680") return { cost: 2500, price: null };
            }
            return { cost: 600, price: null };
        // case 'Credito y Casas':
        //     return { cost: additional_info?.cost, price: additional_info?.price };
        case "Inquiries":
            cost = 1000; // 50 dollars will be added for credit report to make it $1000
            return { cost, price: null };
        case "Rapid Rescore":
            cost += additional_info?.eq ? 150 : 0;
            cost += additional_info?.ex ? 150 : 0;
            cost += additional_info?.tu ? 150 : 0;
            return { cost, price: null };
        case "ID Theft Alert":
            cost += additional_info?.eq ? 50 : 0;
            cost += additional_info?.ex ? 50 : 0;
            cost += additional_info?.tu ? 50 : 0;
            return { cost, price: null };
        case "ID Theft":
            cost = 600;
            const accounts = +(additional_info?.accounts_negative || 0);
            const additional_accounts = accounts > 6 ? accounts - 6 : 0;
            cost += additional_accounts * 100;
            return { cost, price: null };
        case "Public Records":
            cost = (additional_info?.records || 0) * 800;
            return { cost, price: null };
        case "Mailing Address":
            return { cost: 150, price: null };
        case "Score Simulator":
            cost = (additional_info?.simulations || 0) * 100;
            return { cost, price: null };
        case "Regular":
            cost = additional_info?.payment_option === 0 ? 1300 : 1600;
            return { cost, price: null };
        case "New Credit Card":
            if (additional_info?.price) {
                return { cost: +additional_info?.price - 200, price: null };
            }
            return { cost: 250, price: null };
        case "Remove Disputes":
            return { cost: 400, price: 400 };
        case "Homestead":
            cost =
                additional_info?.property_value && additional_info?.property_value > 600000
                    ? 650
                    : 450;
            return { cost, price: null };
        case "Living Trust":
            return { cost: 1750, price: null };
        case "VIP Credit":
            const n = +(additional_info?.accounts_negative || 0);
            if (n <= 5) {
                cost = 5000;
            } else if (n <= 10) {
                cost = 7500;
            } else if (n <= 15) {
                cost = 10000;
            } else {
                cost = 15000;
            }
            return { cost, price: null };
        case "Business VIP":
            return { cost: 2500, price: null };
        case "Funding":
            return { cost: 0, price: null };
        case "Tradelines Funding":
            cost = 1000 * (additional_info?.tradelines || 0);
            return { cost, price: null };
        case "Zero to Hero":
            cost = additional_info?.type === 'CV' ? 1800 : 2500;
            return { cost, price: null };

    }
    return { price: null, cost: 0 };
};

export const getProgramMaxPrice = (
    program: string,
    cr_pricing: number,
    additional_info?: IAdditionalInfo
): number | null => {
    switch (program) {
        case "Regular":
            return (additional_info?.payment_option === 0 ? 1500 : 1800) + cr_pricing;
        case "Homestead":
            return (
                (additional_info?.property_value && additional_info?.property_value > 600000
                    ? 800
                    : 600) + cr_pricing
            );
        case "Living Trust":
            return 2300;
        case "Club Fico Guaranteed":
            if (additional_info?.club_type === "VIP") {
                if (additional_info.club === "CLUB 620") return 1100 + cr_pricing;
                if (additional_info.club === "CLUB 640") return 1700 + cr_pricing;
                if (additional_info.club === "CLUB 660") return 2300 + cr_pricing;
                if (additional_info.club === "CLUB 680") return 3000 + cr_pricing;
            }
            if (additional_info?.club_type === "2nd Contract") return 1100 + cr_pricing;
            return null;
        case "Bankruptcy":
            return additional_info?.bk_type === "SINGLE BK" ? 2598 : 3298;
        case "Business VIP":
            return 3000 + cr_pricing;
        case "Zero to Hero":
            return additional_info?.type === 'CV' ? 2000 + cr_pricing : 3000 + cr_pricing;
        default:
            return null;
    }
};

export const getAgentForProgram = (program: string, agent: string, real_estate_agent?: string, funding_agent?: string) => {
    switch (program) {
        case 'Credito y Casas':
            return real_estate_agent || agent;
        case 'Funding':
        case 'Funding - Business':
        case 'Funding - Personal':
        case 'Funding - Equity':
            return funding_agent || agent;
        default:
            return agent;
    }
}

const getProgramName = (
    program: string,
    change_club?: boolean,
    club?: string,
    club_type?: string,
    funding_type?: string,
) => {
    if (!change_club) return program;
    if (program === 'Funding') return "Funding - " + funding_type;
    if (program !== "Club Fico Guaranteed") return program;
    if (club_type === "Regular") return club;
    return club + " " + club_type;
};

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin

export const newProgram = (
    id: string,
    agent_id: string,
    program: any,
    change_club?: boolean,
    lead?: boolean,
    validation?: boolean,
) => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    return {
        // client_id: id,
        ...(lead ? { lead_id: id } : { client_id: id }),
        id: program.id,
        name: getProgramName(program.name, change_club, program.club, program.club_type, program.funding_type),
        goal: program.goal,
        contract_open: (dayjs as any)().tz("America/Los_Angeles").format("YYYY-MM-DD"),
        contact_close: null,
        payment_type: program.payment_type,
        cost: program.payment_option === 0 && !validation ? program.cost * 0.9 : program.cost,
        price: program.payment_option === 0 && !validation ? program.price * 0.9 : program.price,
        agent_id: agent_id,
        status: "ACTIVE",
        accounts_positive: program.accounts_positive,
        accounts_negative: program.accounts_negative,
        validated_by: program.validated_by,
        special_pricing: program.special_pricing,
        info: getInfoForProgram({ ...program.info, ...program }),
    };
};

export const formatProgramValidation = (info: any, data: any) => {
    return {
        agent_id: info.agent_id,
        created_at: info.created_at,
        id: info.id,
        proposal_id: info.proposal_id,
        status: info.status,
        info: {
            client_name: info.info.client_name,
            lead_name: info.info.lead_name,
            id: info.info.id,
            proposal_id: info.info.proposal_id,
            program: {
                ...data,
                info: getInfoForProgram({ ...data }),
            },
        },
    };
};

export const getInfoForProgram = (info: IAdditionalInfo) => {
    return {
        tradelines: info.tradelines || undefined,
        bk_type: info.bk_type || undefined,
        inquiries: info.inquiries || undefined,
        simulations: info.simulations || undefined,
        records: info.records || undefined,
        ex: info.ex || undefined,
        eq: info.eq || undefined,
        tu: info.tu || undefined,
        state: info.state || undefined,
        creditor_1: info.creditor_1 || undefined,
        creditor_2: info.creditor_2 || undefined,
        creditor_3: info.creditor_3 || undefined,
        collection_amount_1: info.collection_amount_1 || undefined,
        collection_amount_2: info.collection_amount_2 || undefined,
        collection_amount_3: info.collection_amount_3 || undefined,
        account_1: info.account_1 || undefined,
        account_2: info.account_2 || undefined,
        account_3: info.account_3 || undefined,
        auth0_id: (info as any).auth0_id || undefined,
        notes_credit: (info as any).notes_credit || undefined,
        notes_validation: (info as any).notes_validation || undefined,
        credit_report: (info as any).credit_report || undefined,
        club: (info as any).club || undefined,
        special_pricing: (info as any).special_pricing || undefined,
        payment_option: (info as any).payment_option,
        estimated_deposit: (info as any).estimated_deposit || undefined,
        estimated_monthly: (info as any).estimated_monthly || undefined,
        club_type: info.club ? (info as any).club_type : undefined,
        account_numbers: (info as any).account_numbers || undefined,
        property_value: (info as any).property_value || undefined,
        amount_approved: info.amount_approved || undefined,
        agent_commission: (info as any).agent_commission || undefined,
        commission_percent: (info as any).commission_percent || undefined,
        funding_type: (info as any).funding_type || undefined,
        num_collections: (info as any).num_collections || undefined,
        num_inquiries: (info as any).num_inquiries || undefined,
        num_public_records: (info as any).num_public_records || undefined,
        num_open_accounts: (info as any).num_open_accounts || undefined,
        pay_day: (info as any).pay_day || undefined,
        type: (info as any).type || undefined,
    };
};

const getTimeDiff = (d1: dayjs.Dayjs, d2: dayjs.Dayjs) => {
    const minDiff = d1.diff(d2, "minutes");
    if (minDiff < 60) return d1.diff(d2, "minutes") + 1 + " min ago.";
    if (minDiff > 60 && minDiff < 1440) {
        if (d1.diff(d2, "hours") === 1) return d1.diff(d2, "hours") + " hour ago.";
        return d1.diff(d2, "hours") + " hours ago.";
    } else {
        if (d1.diff(d2, "days") === 1) return d1.diff(d2, "days") + " day ago.";
        else return d1.diff(d2, "days") + " days ago.";
    }
};

export const formatDashboardNotifications = (notifications: any[]) => {
    const out = [];
    for (const n of notifications) {
        const d1 = dayjs(new Date());
        const d2 = dayjs(n.created_at);
        out.push({
            id: n.id,
            client: n.info.client_name,
            user: n.info.program.info.user_name,
            time: getTimeDiff(d1, d2),
            program: `
                ${n.info.program.name}
                ${n.info.program.info.club_type && n.info.program.info.club_type !== "Regular"
                    ? " " + n.info.program.info.club_type
                    : ""
                }
            `,
        });
    }
    return out;
};
