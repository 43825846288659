import { useAuth0 } from "@auth0/auth0-react";
import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { BrowserRouter } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { SWRConfig } from "swr";
import { ErrorBoundary } from "./components/errorBoundary";
import RealtimeNotifications from "./components/realtime/notifications/RealTimeNotifications";
import { PERMISSIONS } from "./config";
import { auth0Id, loadingAuth, userPermissions, userRoles } from "./config/recoil/atoms";
import { usePermissions } from "./hooks";
import { removeLogs } from "./hooks/removeLogs";
import { notificationApiError } from "./utils";
import { checkSession } from "./utils/auth";
import ScrollTop from "./utils/scrollTop";
import { initiateSocket } from "./utils/socket";
import MainScreen from "./views/MainScreen";

function App() {
    const { hasUserAuthority, checkPermission } = usePermissions();
    const setUserPermissions = useSetRecoilState(userPermissions);
    const setLoadingAuth = useSetRecoilState(loadingAuth);
    const loading = useRecoilValue(loadingAuth);
    const setUserRoles = useSetRecoilState(userRoles);
    const setAuth0Id = useSetRecoilState(auth0Id);
    const userPermissionsValue = useRecoilValue(userPermissions);
    const authId = useRecoilValue(auth0Id);
    const session = checkSession(
        useAuth0(),
        userPermissionsValue,
        setUserPermissions,
        setUserRoles,
        setAuth0Id,
        authId,
        setLoadingAuth,
        loading
    );

    /**
     * Disable console.log on production
     */
    useEffect(() => {
        if (process.env.NODE_ENV !== "development") {
            removeLogs(false, true);
        }
    }, []);
    
    /**
     * Initialize Datadog RUM
     */
    useEffect(() => {
        if (!datadogRum.getInternalContext()) {
            datadogRum.init({
                applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID || "",
                clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || "",
                site: process.env.REACT_APP_DATADOG_SITE || "",
                service: process.env.REACT_APP_DATADOG_SERVICE || "",
                // Specify a version number to identify the deployed version of your application in Datadog
                // version: '1.0.0',
                env: process.env.NODE_ENV,
                sampleRate: 100,
                replaySampleRate: 100,
                trackInteractions: true,
                defaultPrivacyLevel: "allow",
            });
        }

        datadogRum.startSessionReplayRecording();
    }, []);

    useEffect(() => {
        if (authId && userPermissionsValue.length) {
            initiateSocket("admin", authId.toString());
            // subscribeToNotification((err: any, msg: MessageNotification) => {
            //     if (!err) console.log(msg);
            // });
        }
    }, [authId, userPermissionsValue]);

    // // Landbot chat for feedback
    // useScript("https://static.landbot.io/landbot-3/landbot-3.0.0.js", { SameSite: "None; Secure" });
    // useScript("/chatbot.js", { timeout: 3000 });

    if (session) {
        return session;
    }

    return (
        <div className="App">
            <ReactNotifications />
            <BrowserRouter>
                <ScrollTop>
                    <ErrorBoundary>
                        <SWRConfig
                            value={{
                                onError: (error, key) => {
                                    if (error?.message === "Failed to fetch") {
                                        notificationApiError("Failed to fetch data.");
                                        // We can send the error to Sentry,
                                        // or show a notification UI.
                                    }
                                },
                            }}
                        >
                            {hasUserAuthority() && (
                                <RealtimeNotifications
                                    canValidate={checkPermission(
                                        PERMISSIONS.VALIDATE_PROGRAM
                                    )}
                                    canAddProgram={checkPermission(
                                        PERMISSIONS.CLIENT_CREATE_PROGRAM
                                    )}
                                    canValidateSpecialPricing={checkPermission(
                                        PERMISSIONS.VALIDATE_SPECIAL_PRICING_PROGRAM
                                    )}
                                />
                            )}
                            <div id="tooltip-container"></div>
                            <MainScreen />
                        </SWRConfig>
                    </ErrorBoundary>
                </ScrollTop>
            </BrowserRouter>
        </div>
    );
}
export default App;
