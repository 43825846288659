export type FeedBoxItemProps = {
    name?: string;
    text1?: string;
    text2?: string;
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

function FeedBoxItem({ name, text1, text2, Icon }: FeedBoxItemProps) {
    return (
        <div className="flex items-center gap-4 relative mb-5">
            <div className="bg-color1 p-2 rounded-full w-9 h-9 flex items-center justify-center feedBoxItem__iconWrapper ring ring-color8 z-0">
                <Icon className="fill-current text-color2" />
            </div>
            <div className="flex flex-col ">
                <div className="font-inter font-medium text-r-small-medium text-color6">
                    {name}
                </div>
                <div className="font-inter text- text-color6 text-r-very-small">
                    {text1 && text1}
                </div>
                <div className="font-inter text- text-color6 text-r-very-small">
                    {text2 && text2}
                </div>
            </div>
        </div>
    );
}

export default FeedBoxItem;
