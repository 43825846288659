import { API, API_BASEURL, buildUrl, SWR_CONFIG } from "../../config";
import useGetSWR from "../../hooks/useGetSWR";
import { IlocationsRes } from "../../types";
import { CustomSelect } from "./genericDropdowns/CustomSelect";

type InputProps = {
    title?: string;
    placeholder?: string;
    name?: string;
    onChangeFunction?: (arg0: any) => void;
    className?: string;
    inputClassName?: string;
    titleClassname?: string;
    editing?: boolean;
    textBoxClass?: string;
    value?: any;
    displayTextOnly?: boolean;
    isMulti?: boolean;
    control?: React.CSSProperties;
};

export const DropDownLocation = ({
    title,
    placeholder = "",
    name = "",
    onChangeFunction,
    className,
    inputClassName,
    titleClassname,
    editing,
    textBoxClass,
    value = "",
    isMulti = false,
    control,
}: InputProps) => {
    const url = buildUrl(API.ALL_LOCATIONS, {}, API_BASEURL);

    const { data: locationListRes } = useGetSWR<IlocationsRes>(url, SWR_CONFIG.FORMS);

    const onChange = (val: any, action: any) => {
        if (!onChangeFunction) return "";

        if (action.action === "clear") onChangeFunction(isMulti ? [] : "");
        else onChangeFunction(isMulti ? val : val.value);
    };

    const toLabelValue = () => {
        const options = [];
        if (!locationListRes) return [];
        for (const location of locationListRes?.location) {
            options.push({ value: location.id, label: location.name });
        }
        return options;
    };

    const getValue = () => {
        if (!locationListRes) return "";
        const location = locationListRes?.location.find((l) => l.id === value);
        if (!location) return "";
        return { label: location.name, value: value };
    };

    return (
        <div className="flex flex-col">
            <CustomSelect
                title={title}
                placeholder={placeholder}
                name={name}
                containerClassname="capitalize"
                onChangeFunction={onChange}
                className={className}
                titleClassName={titleClassname}
                editing={editing}
                selectStyles={selectStyles}
                value={isMulti ? value : getValue()}
                options={toLabelValue()}
                isObject
                multi={isMulti}
                control={control}
            />
        </div>
    );
};

const selectStyles = (editing: boolean) => {
    return {
        control: (state: any) => ({
            border: "1px solid var(--color-color3)",
            color: !editing ? "var(--color-color1)" : "var(--color-color7)",
            display: "flex",
            outline: "2px solid transparent",
            outlineOffset: "2px",
            borderRadius: "7px",
            backgroundColor: !editing ? "var(--color-color15)" : "",
            fontSize: "14px",
            // height: "40px",
        }),
        dropdownIndicator: (provided: any) => ({
            ...provided,
            padding: "8px",
        }),
        indicatorSeparator: () => ({
            display: "none",
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            padding: "0 8px",
        }),
    };
};
